import React from 'react';
import { Row, Col, ColorPicker, InputNumber,  Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostPropertyEdit } from '../../../../Post/actions';
import { actionSpacePropertyEdit } from '../../../actions';
import { ReloadOutlined } from '@ant-design/icons';
import fonts from '../../../../../configs/fonts';
import { Dictionary } from '@onaio/utils';
import './style.css';
const { Option } = Select;

interface settingsProps {
  scope: string;
  config: Dictionary[];
  label: string;
  disabled: (value: boolean) => void;
}

interface updatePostProps {
    value: string | number | undefined | null;
    property: string;
    objectKey: string;
 }

export const Settings = (props: settingsProps) => {
    const { scope, disabled, config, label } = props;
    const space = useSelector((store: Dictionary) => store.space);
    const post = useSelector((store: Dictionary) => store.post);
    const dispatch = useDispatch();

    const handleUpdate = (args: updatePostProps) => {
        const { property, value, objectKey } = args;
        if (scope === 'post') {
          dispatch(actionPostPropertyEdit({
              property: property,
              value: value,
              objectKey: objectKey
          }))
        }
        if (scope === 'space') {
          dispatch(actionSpacePropertyEdit({
              property: objectKey,
              value: value,
          }))
        }
    }

    return (
      <div className="style-settings">
       { config.map((item: Dictionary) => (
         <>
         { item.type === 'number' &&
            <Row
            gutter={5}
            style={{ marginBottom: '10px'}}
            >
              <Col xs={12}>
          <label>{item.label}</label>
          </Col>
          <Col xs={12}>
          <InputNumber 
            value={
              scope === 'post' 
                ? post?.config?.[item.value] ?? undefined // Use nullish coalescing to default to 0
                : space?.config?.[item.value] ?? undefined
            }
            placeholder={item.placeholder}
            defaultValue={item.defaultValue}
            onChange={(value) => {
              handleUpdate({
                value: value,
                property: 'config',
                objectKey: item.value 
              });
            }}
          />
        </Col>
          </Row>
         }
         { item.type === 'color' &&
          <Row 
            gutter={5}
            style={{ marginBottom: '10px'}}
          >
          <Col xs={12}>
          <label>{item.label}</label>
          </Col>
          <Col xs={12}>
         <ColorPicker 
         showText
         allowClear
         defaultValue={item.defaultValue}
         disabledAlpha={true}
         onOpenChange={(e) => e ? disabled(true) : disabled(false)}
         value={scope === 'post' ? post?.config?.[item.value] : space?.config?.[item.value] }
         onChangeComplete={(value: any | string) => {
            handleUpdate({
                value: value.cleared ? null : `#${value.toHex()}`,
                property: 'config',
                objectKey: item.value
            })
         }}
         presets={[
          {
            label: 'Palette',
            colors: [
              '#000000',
              '#737373',
              '#545454',
              '#A6A6A6',
              '#D9D9D9',
              '#FFFFFF',
              '#FF1616',
              '#FF5757',
              '#5E17EB',
              '#964B00',
              '#03989E',
              '#00C2CB',
              '#5CE1E6',
              '#1890FF',
              '#38B6FF',
              '#5271FF',
              '#004AAD',
              '#008037',
              '#7ED957',
              '#C9E265',
              '#FFDE59',
              '#FFBD59',
              '#FF914D',
            ],
          },
        ]}
        />
        </Col>
        </Row>
        }
        { item.type === 'font' &&
          <Row 
           style={{ marginBottom: '10px'}}
           gutter={5}
           >
            <Col xs={12}><label>{item.label}</label></Col>
            <Col xs={12}>
              <Select
              value={scope === 'post' ? post?.config?.[item.value] : space?.config?.[item.value] }
              showSearch
              style={{ width: '125px' }}
              allowClear
              placeholder={item.placeholder}
              onChange={(value) => { 
                 handleUpdate({ 
                 value: value,
                 property: 'config',
                 objectKey: item.value
               })
               
             }}
              >
            { fonts.map((group) => (
                group.options.map((item) => (
                    <Option 
                      key={item.value} 
                      value={item.value}
                      >
                        { item.label }
                    </Option>
                ))
                ))}
              </Select>
            </Col>
          </Row>
        }
        </>
       ))}
       </div>
    )

}