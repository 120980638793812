import React from 'react';
import { Menu } from 'antd';
import { PostComponentType } from '../../../../configs/component-types';
import { AddComponentMenuLink, AddComponentMenuProps } from './add-component-menu';
import {
  actionComponentAdd,
  actionConfigComponentIndexActive,
  actionConfigDrawerOpenEdit,
  actionConfigActiveLayerEdit,
  actionConfigActiveColumnEdit,
} from '../../actions';
import {
  AlignLeftOutlined,
  AppstoreOutlined,
  EnvironmentOutlined,
  FilterOutlined,
  LayoutOutlined,
  LineChartOutlined,
  NumberOutlined,
  PictureOutlined,
  PlaySquareOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

export interface ComponentsMenuBuilderProps extends AddComponentMenuProps {
  dispatch: any;
  postType: string;
  componentsLength: number;
  addComponentActionCreator?: typeof actionComponentAdd;
  componentIndexActiveActionCreator?: typeof actionConfigComponentIndexActive;
  drawerOpenEditActionCreator?: typeof actionConfigDrawerOpenEdit;
  activeLayerEditActionCreator?: typeof actionConfigActiveLayerEdit;
  activeColumnEditActionCreator?: typeof actionConfigActiveColumnEdit;
  index?: number; // index the component would be added added at, if not provided component will be added as last
  regionIndex?: number; // region index for layouts
  layoutId?: string; //  layout id for layouts
  spaceComponent?: boolean; // space component
  activeMenuItems?: PostComponentType[]; // menu items to be made available for respective types of component
}

/** default component props */
export const defaultProps: Partial<ComponentsMenuBuilderProps> = {
  addComponentActionCreator: actionComponentAdd,
  componentIndexActiveActionCreator: actionConfigComponentIndexActive,
  drawerOpenEditActionCreator: actionConfigDrawerOpenEdit,
  activeLayerEditActionCreator: actionConfigActiveLayerEdit,
  activeColumnEditActionCreator: actionConfigActiveColumnEdit,
};

const componentsMenuBuilder = (configs: ComponentsMenuBuilderProps): JSX.Element => {
  const {
    regionIndex,
    layoutId,
    activeMenuItems,
    index,
    spaceComponent,
    dispatch,
    postType,
    componentsLength,
  } = configs;

  const addComponentActionCreator = componentsMenuBuilder.defaultProps.addComponentActionCreator;
  const componentIndexActiveActionCreator =
    componentsMenuBuilder.defaultProps.componentIndexActiveActionCreator;
  const drawerOpenEditActionCreator =
    componentsMenuBuilder.defaultProps.drawerOpenEditActionCreator;
  const activeColumnEditActionCreator =
    componentsMenuBuilder.defaultProps.activeColumnEditActionCreator;
  const activeLayerEditActionCreator =
    componentsMenuBuilder.defaultProps.activeLayerEditActionCreator;

  const items: AddComponentMenuLink[] = [
    {
      title: 'Text',
      type: 'text',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'text',
                text: 'text',
                width: 'small',
                region: regionIndex,
                layout: layoutId,
                heightMetric: 'auto',
                spaceComponent: spaceComponent,
              },
            })
          );
        }
      },
    },
    {
      title: 'HTML',
      type: 'html',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'html',
                text: 'text',
                width: 'small',
                region: regionIndex,
                layout: layoutId,
                spaceComponent: spaceComponent,
              },
            })
          );
        }
      },
    },
    {
      title: 'Map',
      type: 'map',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'map',
                region: regionIndex,
                layout: layoutId,
                height: 400,
                latitude: 0,
                longitude: 0,
                zoom: 2,
                columns: [],
                layers: [],
                default: 'none',
                width: postType === 'map' || layoutId ? 'large' : 'small',
                enableScrollZoom: true,
                styleOption: 'default-style',
                styleColor: '#f9f9f9',
                defaultLongitude: 0,
                defaultLatitude: 0,
                defaultZoom: 2,
              },
            })
          );
        }
      },
    },
    {
      title: 'Chart',
      type: 'chart',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'chart',
                region: regionIndex,
                layout: layoutId,
                properties: [],
                hideLabels: false,
                padding: [10, 10, 60, 100],
                height: 400,
                width: postType === 'map' || layoutId ? 'large' : 'small',
                source: '',
                cube: '',
                chartType: '',
                xAxis: {},
                series: [],
                filterBy: {},
                autoRotate: true,
                autoEllipsis: true,
                opacity: 1,
                ytextMaxLength: 40,
                xtextMaxLength: 40,
                yLabelOffset: undefined,
                xLabelOffset: undefined,
                yLabelRotate: undefined,
                xLabelRotate: undefined,
                legend: true,
                labelFill: '#555555',
                yAxisMin: 0,
                circleSize: 5,
                categoryTickCount: 50,
              },
            })
          );
        }
      },
    },
    {
      title: 'Card',
      type: 'card',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'card',
                region: regionIndex,
                layout: layoutId,
                pagination: true,
                properties: [],
                cardLayout: '24',
                width: postType === 'map' || layoutId ? 'large' : 'small',
                perRow: '1',
                rows: 1,
                cardGutter: 20,
                cardHeight: 20,
                rowMargin: 2,
              },
            })
          );
        }
      },
    },
    {
      title: 'Table',
      type: 'table',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'table',
                region: regionIndex,
                layout: layoutId,
                pagination: true,
                properties: [],
                width: postType === 'map' || layoutId ? 'large' : 'small',
                showHeader: true,
              },
            })
          );
        }
      },
    },
    {
      title: 'Image',
      type: 'image',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'image',
                region: regionIndex,
                layout: layoutId,
                width: postType === 'map' || layoutId ? 'large' : 'small',
                spaceComponent: spaceComponent,
              },
            })
          );
        }
      },
    },
    {
      title: 'Layout',
      type: 'layout',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'layout',
                layoutId: '24',
                layout: layoutId,
                region: regionIndex,
                positionVertical: 'top',
                positionHorizontal: 'left',
                horizontalValue: 40,
                verticalValue: 40,
                paddingLeft: postType === 'map' ? 20 : 0,
                paddingRight: postType === 'map' ? 20 : 0,
                paddingTop: postType === 'map' ? 20 : 0,
                paddingBottom: postType === 'map' ? 10 : 0,
                percentHeight: 30,
                percentWidth: 30,
                pixelWidth: 280,
                pixelHeight: 80,
                width: postType === 'map' || layoutId ? 'large' : 'small',
                layoutBackgroundColor: postType === 'map' ? '#ffffff' : null,
                layoutShadow: false,
                marginRight: 0,
                marginTop: 0,
                widthMetric: 'px',
                heightMetric: 'px',
                rows: 1,
                columns: 2,
                tabs: [],
                collapse: [],
                spaceComponent: spaceComponent,
              },
            })
          );
        }
      },
    },
    {
      title: 'Number',
      type: 'number',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'number',
                region: regionIndex,
                layout: layoutId,
                properties: [],
                numberHeight: 90,
                width: postType === 'map' || layoutId ? 'large' : 'small',
                color: '#000000',
                height: 40,
              },
            })
          );
        }
      },
    },
    {
      title: 'Reference',
      type: 'reference',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'reference',
                reference: true,
                region: regionIndex,
                layout: layoutId,
              },
            })
          );
        }
      },
    },
    {
      title: 'Button',
      type: 'button',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'button',
                format: 'button',
                region: regionIndex,
                layout: layoutId,
                width: 'small',
                backgroundColor: '#f9f9f9',
                spaceComponent: spaceComponent,
              },
            })
          );
        }
      },
    },
    {
      title: 'Filter',
      type: 'filter',
      onClick: () => {
        if (addComponentActionCreator) {
          dispatch(
            addComponentActionCreator({
              index,
              value: {
                type: 'filter',
                region: regionIndex,
                layout: layoutId,
                properties: [],
                width: postType === 'map' || layoutId ? 'large' : 'small',
              },
            })
          );
        }
      },
    },
  ];

  const filteredItems = activeMenuItems
    ? items.filter((item) => activeMenuItems.indexOf(item.type as PostComponentType) >= 0)
    : items;

  return (
    <Menu>
      {filteredItems.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            item.onClick();
            // set active component
            if (componentIndexActiveActionCreator) {
              dispatch(
                componentIndexActiveActionCreator({
                  value: componentsLength,
                })
              );
            }
            // set settings drawer open
            if (drawerOpenEditActionCreator) {
              dispatch(
                drawerOpenEditActionCreator({
                  value: true,
                })
              );
            }
            // set no active layer
            if (activeLayerEditActionCreator) {
              dispatch(
                activeLayerEditActionCreator({
                  value: -1,
                })
              );
            }
            // set no active column
            if (activeColumnEditActionCreator) {
              dispatch(
                activeColumnEditActionCreator({
                  value: -1,
                })
              );
            }
            // save post
          }}
        >
          {item.type === 'text' && (
            <>
              <AlignLeftOutlined />
              &nbsp; Text
            </>
          )}
          {item.type === 'html' && (
            <>
              <AlignLeftOutlined />
              &nbsp; HTML
            </>
          )}
          {item.type === 'map' && (
            <>
              <EnvironmentOutlined />
              &nbsp; Map
            </>
          )}
          {item.type === 'reference' && (
            <>
              <PlaySquareOutlined />
              &nbsp; Reference
            </>
          )}
          {item.type === 'button' && (
            <>
              <UnorderedListOutlined />
              &nbsp; Button
            </>
          )}
          {item.type === 'chart' && (
            <>
              <LineChartOutlined />
              &nbsp; Chart
            </>
          )}
          {item.type === 'card' && (
            <>
              <AppstoreOutlined />
              &nbsp; Cards
            </>
          )}
          {item.type === 'table' && (
            <>
              <TableOutlined />
              &nbsp; Table
            </>
          )}
          {item.type === 'image' && (
            <>
              <PictureOutlined />
              &nbsp; Image
            </>
          )}
          {item.type === 'layout' && (
            <>
              <LayoutOutlined />
              &nbsp; Layout
            </>
          )}
          {item.type === 'number' && (
            <>
              <NumberOutlined />
              &nbsp; Number
            </>
          )}
          {item.type === 'filter' && (
            <>
              <FilterOutlined />
              &nbsp; Filter
            </>
          )}
        </Menu.Item>
      ))}
    </Menu>
  );
};

componentsMenuBuilder.defaultProps = defaultProps;

export { componentsMenuBuilder };
