import produce from 'immer';
import moment from 'moment';
import { message } from 'antd';
import { getMapLayerId } from '../components/Post/Map/components/MapInstance/helpers';
import { makeId, randInt } from './helpers';
import { resetLayersPosition } from './utils';
import mixpanel from 'mixpanel-browser';

const defaultState = {
  components: [],
  data: {},
  errors: {},
};


/*
 * - 1. POST: global post reducers
 * - 2. POST COMPONENTS: global post component reducers
 * - 3. POST COMPONENT IMAGE: image component reducers
 * - 4. POST COMPONENT CHART: chart component reducers
 * - 5. POST COMPONENT MAP: map component reducers
 * - 6. POST COMPONENT TABLE: table component reducers
 */

//  POST
// ************************************************************
const post = (state = defaultState, action) => {
  let nextState;
  switch (action.type) {
    case 'POST_GET':
      nextState = produce(state, () => action.data);
      return nextState;
    case 'POST_CLEAR_CONFIG':
      nextState = {
        components: [],
        data: {},
        errors: {},
      };
      return nextState;
    case 'POST_LAYOUT_TAB_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentId, value } = action.data;
        const layout = draftState.components.find((item) => item.id === componentId);
        layout.activeTab = value;
      });
      return nextState;
    case 'POST_COMPONENT_QUERY':
      nextState = produce(state, (draftState) => {
        const { componentId, data, query, type } = action.data;
        let componentType = type;
        let entityId = componentId;
        if (typeof entityId === 'object') {
          entityId = componentId.id;
          componentType = componentId.layerType ? 'layer' : componentId.type;
        }
        const id = componentType ? `${componentType}-${entityId}` : entityId;
        draftState.data[id] =
          data?.length > 0 &&
          data.map((item) => {
            return {
              ...item,
              // add id to be used if a component has pagination e.g cards else the child
              // component will not update if we switch pages and index was used
              id: makeId(),
            };
          });

        /**
         * Add query history to store. Will be useful in cases where we need
         * to check if we need to refetch component data. By comparing the history
         * object with the one generated at the time, we can bypass making the API call
         */
        if (!draftState.sourceQueryHistory) {
          draftState.sourceQueryHistory = {};
        }
        draftState.sourceQueryHistory[componentId] = query;
      });
      return nextState;
    case 'POST_COMPONENT_SETTING_EDIT':
      nextState = produce(state, (draftState) => {
        const {
          itemIndex,
          componentIndex,
          childIndex,
          objectKey,
          arrayIndex,
          parents,
          value,
          property,
        } = action.data;
        const target = draftState.components[componentIndex];

        const id =
          target && target.filters && target.filters?.[itemIndex] && target.filters?.[itemIndex].id;
        if (!parents?.length) {
          if (arrayIndex !== undefined) {
            target[property][arrayIndex] = value;
          } else if (objectKey !== undefined) {
            if (!target[property]) {
              target[property] = {};
            }
            target[property][objectKey] = value;
          } else {
            if (target) {
              target[property] = value;
            }
            if (
              (property === 'limit' && target[property] && value === undefined) ||
              (property === 'offset' && target[property] && value === undefined)
            ) {
              target[property] = value;
            }
            if (value === false) {
              target[property] = false;
            }
            if (value === 0) {
              target[property] = 0;
            }
            if (value === '' && target?.[property]) {
              target[property] = '';
            }
          }
        }
        if (parents?.length === 1 && target?.[parents[0]]) {
          if (arrayIndex !== undefined) {
            target[parents[0]][itemIndex][property][arrayIndex] = value;
          } else if (objectKey !== undefined) {
            // set objet keyed property that didn't exist previously on a component
            if (!target[parents[0]][itemIndex][property]) {
              target[parents[0]][itemIndex][property] = {};
            }
            target[parents[0]][itemIndex][property][objectKey] = value;
          } else {
            if (!target[parents[0]]?.[itemIndex]?.[property]) {
              target[parents[0]][itemIndex][property] = {};
            }
            target[parents[0]][itemIndex][property] = value;
          }
        }
        if (parents?.length === 2) {
          if (target[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]) {
            target[parents[0]][childIndex][parents[1]][itemIndex][property] = value;
          }
        }
        // reset map if geometry changes
        if (property === 'geometryIndex' && value !== null && target?.key) {
          target.key = makeId();
        }
        if (target && target.type === 'filter' && parents && parents[0] === 'filters') {
          // set global filter values
          if (id) {
            draftState.components.forEach((component) => {
              if (component.filters && component.filters.length) {
                const filterWithMatchingId = component.filters.find((filter) => filter.id === id);
                if (
                  filterWithMatchingId &&
                  filterWithMatchingId.isGlobal &&
                  property !== 'expose'
                ) {
                  filterWithMatchingId[property] = value;
                }
              }
              if (component.layers) {
                component.layers.forEach((layer) => {
                  if (layer.filters && layer.filters.length) {
                    const filterWithMatchingId = layer.filters.find((filter) => filter.id === id);
                    if (
                      filterWithMatchingId &&
                      filterWithMatchingId.isGlobal &&
                      property !== 'expose'
                    ) {
                      filterWithMatchingId[property] = value;
                    }
                  }
                });
              }
            });
            if (id && action.data.property === '2') {
              if (!draftState.events) {
                draftState.events = [];
              }
              draftState.events.unshift({ event: 'onFilterChange', target: id, action: action });
            }
          }
        }
      });
      return nextState;
    case 'POST_ARRAY_ITEM_ADD':
      nextState = produce(state, (draftState) => {
        const { componentIndex, property, refreshKey, parents, itemIndex, value } = action.data;
        const target = draftState.components[componentIndex];
        if (refreshKey) {
          target.key = makeId();
        }
        if (!parents) {
          if (!target[property]) {
            target[property] = [];
          }
          target[property].push(value);
        }
        if (parents && parents.length === 1) {
          if (!target[parents[0]][itemIndex][property]) {
            target[parents[0]][itemIndex][property] = [];
          }
          target[parents[0]][itemIndex][property].push(value);
        }

        if (target.type === 'filter') {
          const globalFilterValue = {
            ...value,
            isGlobal: true,
          };
          draftState.components.forEach((component) => {
            if (target.componentsWithGlobalFilters && target.componentsWithGlobalFilters.length) {
              if (target.componentsWithGlobalFilters.includes(component.id)) {
                if (!parents) {
                  if (!component[property]) {
                    component[property] = [];
                  }
                  component[property].push(globalFilterValue);
                }
                if (parents && parents.length === 1) {
                  if (!component[parents[0]][itemIndex][property]) {
                    component[parents[0]][itemIndex][property] = [];
                  }
                  component[parents[0]][itemIndex][property].push(globalFilterValue);
                }
              }
              if (component.layers && component.layers.length) {
                component.layers.forEach((layer) => {
                  const layerWithGlobalFilters = target.componentsWithGlobalFilters
                    .map((component) => component.split('/')[1])
                    .filter((layer) => layer);

                  if (layerWithGlobalFilters.includes(layer.id)) {
                    if (!parents) {
                      if (!layer[property]) {
                        layer[property] = [];
                      }
                      layer[property].push(globalFilterValue);
                    }
                    if (parents && parents.length === 1) {
                      if (!layer[parents[0]][itemIndex][property]) {
                        layer[parents[0]][itemIndex][property] = [];
                      }
                      layer[parents[0]][itemIndex][property].push(globalFilterValue);
                    }
                  }
                });
              }
            }
          });
        }
      });
      return nextState;
    case 'POST_ARRAY_ITEM_MOVE':
      nextState = produce(state, (draftState) => {
        const { componentIndex, property, parents, itemIndex, fromIndex, toIndex, childIndex } =
          action.data;
        let movedItem;
        if (!parents) {
          movedItem = draftState.components[componentIndex][property][fromIndex];
          draftState.components[componentIndex][property].splice(fromIndex, 1);
          draftState.components[componentIndex][property].splice(toIndex, 0, movedItem);
        }
        if (parents?.length === 1) {
          movedItem =
            draftState.components[componentIndex][parents[0]][itemIndex][property][fromIndex];
          draftState.components[componentIndex][parents[0]][itemIndex][property].splice(
            fromIndex,
            1
          );
          draftState.components[componentIndex][parents[0]][itemIndex][property].splice(
            toIndex,
            0,
            movedItem
          );
        }
      });
      return nextState;
    case 'POST_ARRAY_ITEM_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, property, parentProperty, itemIndex, childIndex, value } =
          action.data;

        draftState.components[componentIndex][parentProperty][childIndex][property][itemIndex] =
          value;
      });
      return nextState;
    case 'POST_ARRAY_ITEM_DELETE':
      nextState = produce(state, (draftState) => {
        const { componentIndex, property, parents, itemIndex, childIndex } = action.data;
        const target = draftState.components[componentIndex];
        const id =
          target && target.filters && target.filters[itemIndex] && target.filters[itemIndex].id;

        if (!parents) {
          target[property].splice(itemIndex, 1);
        }
        if (parents?.length === 1) {
          target[parents[0]][childIndex][property].splice(itemIndex, 1);
        }
        if (target.type === 'filter') {
          if (id) {
            draftState.components.forEach((component) => {
              if (target.componentsWithGlobalFilters && target.componentsWithGlobalFilters.length) {
                if (target.componentsWithGlobalFilters.includes(component.id)) {
                  if (!parents) {
                    if (component[property]) {
                      component[property] = component[property].filter((item) => item.id !== id);
                    }
                  }
                  if (parents && parents.length === 1) {
                    if (!component[parents[0]][itemIndex][property]) {
                      component[parents[0]][itemIndex][property] = [];
                    }
                    component[parents[0]][itemIndex][property] = component[parents[0]][itemIndex][
                      property
                    ].filter((item) => item.id !== id);
                  }
                }
                if (component.layers && component.layers.length) {
                  component.layers.forEach((layer) => {
                    const layerWithGlobalFilters = target.componentsWithGlobalFilters
                      .map((component) => component.split('/')[1])
                      .filter((layer) => layer);

                    if (layerWithGlobalFilters.includes(layer.id)) {
                      if (!parents) {
                        if (!layer[property]) {
                          layer[property] = [];
                        }
                        layer[property] = layer[property].filter((item) => item.id !== id);
                      }
                      if (parents && parents.length === 1) {
                        if (!layer[parents[0]][itemIndex][property]) {
                          layer[parents[0]][itemIndex][property] = [];
                        }
                        layer[parents[0]][itemIndex][property] = layer[parents[0]][itemIndex][
                          property
                        ].filter((item) => item.id !== id);
                      }
                    }
                  });
                }
              }
            });
          }
        }
      });
      return nextState;
    case 'POST_LIST_ITEM_ADD':
      nextState = produce(state, (draftState) => {
        const { componentIndex, property, parentProperty, itemIndex } = action.data;
        if (itemIndex !== undefined) {
          if (!draftState.components[componentIndex][parentProperty][itemIndex][property]) {
            draftState.components[componentIndex][parentProperty][itemIndex][property] = [];
          }
          draftState.components[componentIndex][parentProperty][itemIndex][property].push({
            id: makeId(),
            lineWidth: 0.5,
            layerOpacity: 1,
            lineColor: '#777',
            visible: true,
          });
        } else {
          if (!draftState.components[componentIndex][property]) {
            draftState.components[componentIndex][property] = [];
          }
          draftState.components[componentIndex][property].push({
            id: makeId(),
            lineWidth: 0.5,
            layerOpacity: 1,
            lineColor: '#777',
            visible: true,
          });
        }
      });
      return nextState;
    case 'POST_LIST_ITEM_MOVE':
      nextState = produce(state, (draftState) => {
        const { parentItemIndex, parentProperty, nextIndex, componentIndex, property } =
          action.data;
        //const list = draftState.components[componentIndex][property];
        //const itemToMove = list[parentItemIndex];
        //list.splice(parentItemIndex, 1);
        //list.splice(nextIndex, 0, itemToMove);
      });
      return nextState;
    case 'POST_LIST_ITEM_DELETE':
      nextState = produce(state, (draftState) => {
        const { componentIndex, property, parentProperty, childIndex, itemIndex } = action.data;
        if (childIndex !== undefined) {
          draftState.components[componentIndex][parentProperty][childIndex][property].splice(
            itemIndex,
            1
          );
        } else {
          draftState.components[componentIndex][property].splice(itemIndex, 1);
        }
      });
      return nextState;
    case 'POST_COMPONENT_COPY':
      nextState = produce(state, (draftState) => {
        const { componentIndex, itemIndex, value } = action.data;
        const component = draftState.components[componentIndex];
        value.id = makeId();
        if (component.properties) {
          component.properties.forEach((column) => (column.id = makeId()));
        }
        if (itemIndex || itemIndex === 0) {
          if (draftState.components[componentIndex]?.layers) {
            value.layerName = `${value.layerName || 'Layer'} copy`;
            draftState.components[componentIndex].layers.splice(itemIndex + 1, 0, value);
          }
          if (draftState.components[componentIndex]?.properties) {
            value.propertyAlias = `${value.propertyAlias || 'Column'} copy`;
            draftState.components[componentIndex].properties.splice(itemIndex + 1, 0, value);
          }
        } else {
          value.name = `${value.name || value.type} copy`;
          if (value.region && value.layout) {
            value.region = undefined;
            value.layout = undefined;
            draftState.components.push(value);
          } else {
            draftState.components.splice(componentIndex + 1, 0, value);
          }
        }
      });
      return nextState;
    case 'POST_FILTER_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, itemIndex, childIndex, filter } = action.data;
        if (childIndex !== undefined) {
          if (!draftState.components[componentIndex].layers[childIndex].filters) {
            draftState.components[componentIndex].layers[childIndex].filters = [];
          }
          if (!draftState.components[componentIndex].layers[childIndex].filters[itemIndex]) {
            draftState.components[componentIndex].layers[childIndex].filters.push(filter);
          } else {
            draftState.components[componentIndex].layers[childIndex].filters[itemIndex] = filter;
          }
        } else {
          if (!draftState.components[componentIndex].filters) {
            draftState.components[componentIndex].filters = [];
          }
          if (!draftState.components[componentIndex].filters[itemIndex]) {
            draftState.components[componentIndex].filters.push(filter);
          } else {
            draftState.components[componentIndex].filters[itemIndex] = filter;
          }
        }
      });
      return nextState;
    case 'POST_LIST_ITEM_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, itemIndex, value } = action.data;
        if (itemIndex !== undefined) {
          draftState.components[componentIndex].properties[itemIndex] = {
            ...draftState.components[componentIndex].properties[itemIndex],
            ...value,
          };
        }
      });
      return nextState;
    case 'POST_PROPERTY_EDIT':
      nextState = produce(state, (draftState) => {
        const { property, value, objectKey } = action.data;
        if (objectKey) {
          if (draftState.config === null) {
            draftState.config = {};
          }
          draftState.config[objectKey] = value;
        } else {
          draftState[property] = value;
        }
      });
      return nextState;
    case 'POST_TAGS_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.tags = action.data;
      });
      return nextState;
    case 'POST_IMAGE_URL_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.image = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_PROPERTY_EDIT':
      nextState = produce(state, (draftState) => {
        const { property, componentIndex, value } = action.data;

        draftState.components[componentIndex][property] = value;
      });
      return nextState;
    case 'POST_COMPONENT_MEASURE_ADD':
      nextState = produce(state, (draftState) => {
        if (!draftState.components[action.data.componentIndex].measures) {
          draftState.components[action.data.componentIndex].measures = {};
        }
        draftState.components[action.data.componentIndex].measures[action.data.value] =
          action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MEASURE_DELETE':
      nextState = produce(state, (draftState) => {
        delete draftState.components[action.data.componentIndex].measures[action.data.value];
      });
      return nextState;
    case 'POST_COMPONENT_DIMENSION_DELETE':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].properties.splice(action.data.index, 1);
      });
      return nextState;
    case 'POST_COMPONENT_DIMENSION_DELETE_BY_NAME':
      nextState = produce(state, (draftState) => {
        let valueIndex;
        draftState.components[action.data.componentIndex].properties.forEach((item, index) => {
          if (item.value === action.data.value) {
            valueIndex = index;
          }
        });
        draftState.components[action.data.componentIndex].properties.splice(valueIndex, 1);
      });
      return nextState;
    case 'POST_COMPONENT_DIMENSION_ORDER':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].properties = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_DIMENSION_ADD':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value, type } = action.data;
        const property = {
          value,
          type,
          id: randInt(),
        };
        if (!draftState.components[componentIndex].properties) {
          draftState.components[componentIndex].properties = [];
        }
        draftState.components[action.data.componentIndex].properties.push(property);
      });
      return nextState;
    case 'POST_COMPONENT_DIMENSION_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].properties[action.data.index] = {
          value: action.data.value,
          type: action.data.type,
          id: randInt(),
        };
      });
      return nextState;
    case 'POST_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.edit = action.data.value;
        draftState.key = makeId();
      });
      return nextState;
    case 'POST_ACTIVE_COLUMN_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].activeColumn = action.data.value;
      });
      return nextState;
    case 'POST_ACTIVE_COLUMN_CONNECTED_EDIT':
      nextState = produce(state, (draftState) => {
        if (!draftState.active) {
          draftState.active = {};
        }
        if (!draftState.active[action.data.source]) {
          draftState.active[action.data.source] = {};
          draftState.active[action.data.source].activeColumn = action.data.value;
        }
        if (draftState.active[action.data.source].activeColumn) {
          draftState.active[action.data.source].activeColumn = action.data.value;
        }
      });
      return nextState;
    case 'POST_NAME_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.title = action.data.value;
      });
      return nextState;
    case 'POST_DESCRIPTION_EDIT':
      nextState = produce(state, (draftState) => {
        const { value, componentIndex } = action.data;
        draftState.components[componentIndex].description = value;
      });
      return nextState;
    case 'POST_PUBLIC_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.public = action.data.value;
        draftState.updated = action.data?.updated;
      });
      return nextState;
    case 'POST_CONFIG_EDIT':
      nextState = produce(state, (draftState) => {
        if (!draftState.config) {
          draftState.config = {};
        }
        draftState.config[action.data.property] = action.data.value;
      });
      return nextState;
    case 'POST_TITLE_TOGGLE':
      nextState = produce(state, (draftState) => {
        if (!draftState.config) {
          draftState.config = {};
        }
        draftState.config.showTitle = action.data.value;
      });
      return nextState;
    case 'POST_ACCOUNT_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.account = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_EDIT':
      if (action.data.type === 'add') {
        nextState = produce(state, (draftState) => {
          if (!draftState.components[action.data.index][action.data.field]) {
            draftState.components[action.data.index][action.data.field] = [];
          }
          draftState.components[action.data.index][action.data.field].push(action.data.value);
        });
      } else if (action.data.type === 'delete') {
        nextState = produce(state, (draftState) => {
          draftState.components[action.data.index][action.data.field].splice(
            action.data.value.columnIndex,
            1
          );
        });
      } else if (action.data.type === 'order') {
        nextState = produce(state, (draftState) => {
          const item =
            draftState.components[action.data.index][action.data.field][action.data.currentIndex];
          item.columnIndex = action.data.nextIndex;
          draftState.components[action.data.index][action.data.field].splice(
            action.data.currentIndex,
            1
          );
          draftState.components[action.data.index][action.data.field].splice(
            action.data.nextIndex,
            0,
            item
          );
        });
      } else if (action.data.type === 'edit') {
        nextState = produce(state, (draftState) => {
          // components > [0] > columns > [0] > format
          draftState.components[action.data.index][action.data.field][
            action.data.value.columnIndex
          ][action.data.value.field] = action.data.value.value;
        });
        return nextState;
      } else if (action.data.type === 'update') {
        nextState = produce(state, (draftState) => {
          // components > [0] > columns > [0] > format
          let value;
          if (action.data.value.value !== '') {
            value = parseFloat(action.data.value.value);
          }
          draftState.components[action.data.index][action.data.field][
            action.data.value.columnIndex
          ][action.data.value.field][action.data.value.fieldIndex] = value;
        });
        return nextState;
      } else if (action.data.type === 'move') {
        nextState = produce(state, (draftState) => {
          draftState.components.splice(action.data.index, 1);
          draftState.components.splice(action.data.nextIndex, 0, action.data.value);
          const d = new Date();
          const t = d.getTime();
          draftState.key = t;
        });
      } else {
        nextState = produce(state, (draftState) => {
          const { index, value, field } = action.data;
          draftState.components[index][field] = value;
        });
      }
      return nextState;

    // EVENTS
    // ************************************************************
    case 'POST_COMPONENT_EVENT_ADD':
      nextState = produce(state, (draftState) => {
        if (!draftState.events) {
          draftState.events = [];
        }
        draftState.events.unshift({
          event: action.data.type,
          componentId: action.data.componentId,
          trigger: action.data.event,
          data: action.data.data,
          layer: action.data.layer,
          feature: action.data.feature,
        });
      });
      return nextState;

    //  POST COMPONENTS
    // ************************************************************
    case 'POST_COMPONENT_ADD':
      nextState = produce(state, (draftState) => {
        const { index, value } = action.data;

        draftState.key = makeId();
        const componentId = makeId();
        if (!action.data.value.id) {
          action.data.value.id = componentId;
        }

        // add default map layer
        if (value.type === 'map') {
          value.layers = [];
          value.layers.push({
            id: makeId(),
            key: '',
            layerName: '',
            layerGroup: '',
            source: '',
            layerOpacity: 1,
            fillColor: '#1890ff',
            colorMethod: 'simple',
            colorRange: 'OrRd',
            colorScale: 'ckmeans',
            visible: true,
            icon: {
              id: 'marker-15-custom',
              src: 'https://s3.amazonaws.com/assets.akuko.io/maki-icons/marker-15.svg',
              title: 'marker',
            },
            fillFieldStartColor: '#feb642',
            fillFieldEndColor: '#7f0000',
            iconColor: '#1890ff',
            iconSize: 0.2,
            iconAllowOverlap: true,
            lineWidth: 1,
            fillOutlineColor: '#ffffff',
            circleRadius: 7,
            radiusScale: 'sqrt',
            legend: {
              position: 'below',
            },
          });
        }

        mixpanel.track(`Create ${value.type}`, { component_id: componentId });

        if (index !== undefined) {
          // Explicit check (index !== undefined) to make sure we allow index 0 insertion
          draftState.components.splice(index, 0, value);
        } else {
          draftState.components.push(value);
        }
      });
      return nextState;
    case 'POST_COMPONENT_DELETE':
      nextState = produce(state, (draftState) => {
        const { index } = action.data;
        const target = draftState.components[index];

        // set global filters to [] if component is deleted
        if (target?.type === 'filter' && target?.filters && target?.filters?.length > 0) {
          const globalFilterIds = target.filters.map((filter) => filter.id);
          draftState.components.forEach((component) => {
            if (component.layers && component.layers.length > 0) {
              component.layers.forEach((layer) => {
                let filters = layer.filters;
                if (filters && filters.length > 0) {
                  const newFilters = filters.filter((filterItem) => {
                    return (
                      !globalFilterIds.includes(filterItem.id) ||
                      ![
                        'kJEChbU00o',
                        'iQ0GqpNfz4',
                        'OnnPnK6LzO',
                        'Nv2RUZSuov',
                        'ckZSZmY085',
                        'tE8aF0cLij',
                        'RAf0cyA28I',
                        'PYuYD5ngWe',
                      ].includes(filterItem.id)
                    );
                  });
                  layer.filters = [...newFilters];
                }
              });
            } else {
              let filters = component.filters;
              if (filters && filters.length > 0 && component.filters) {
                const newFilters = filters.filter((filterItem) => {
                  return !globalFilterIds.includes(filterItem.id);
                });
                component.filters = [...newFilters];
              }
            }
          });
        }

        const deletedComponentIds = [];

        const component = draftState.components[index];

        // delete components within cards/layout
        const componentId = component.id;
        deletedComponentIds.push(componentId);

        if (['layout', 'card'].includes(component.type)) {
          const newComponents = draftState.components.filter((item, index) => {
            if (item.layout && item.layout === componentId) {
              if (item.type === 'filter' && item?.filters && item?.filters?.length > 0) {
                const globalFilterIds = item.filters.map((filter) => filter.id);
                draftState.components.forEach((component) => {
                  if (component.layers && component.layers.length > 0) {
                    component.layers.forEach((layer) => {
                      let filters = layer.filters;
                      if (filters && filters.length > 0) {
                        const newFilters = filters.filter((filterItem) => {
                          return !globalFilterIds.includes(filterItem.id);
                        });
                        layer.filters = [...newFilters];
                      }
                    });
                  } else {
                    let filters = component.filters;
                    if (filters && filters.length > 0) {
                      const newFilters = filters.filter((filterItem) => {
                        return !globalFilterIds.includes(filterItem.id);
                      });
                      component.filters = [...newFilters];
                    }
                  }
                });
              }

              deletedComponentIds.push(item.id);
            }
            return !(item.layout && item.layout === componentId);
          });
          draftState.components = newComponents;
        }

        // update component with global filters on component deletion
        draftState.components.forEach((item, index) => {
          if (item?.type === 'filter' && item?.componentsWithGlobalFilters) {
            const newComponentsWIthGlobalFilters = draftState.components[
              index
            ].componentsWithGlobalFilters?.filter((item) => {
              return !deletedComponentIds.includes(item);
            });
            draftState.components[index].componentsWithGlobalFilters =
              newComponentsWIthGlobalFilters;
          }
        });
        // delete component
        draftState.components.splice(index, 1);
        draftState.key = makeId();
      });
      return nextState;
    case 'POST_COMPONENT_MOVE':
      nextState = produce(state, (draftState) => {
        const { index, layoutId, region } = action.data;
        const component = draftState.components[index];
        const isCard =
          draftState.components.filter((item) => item.id === layoutId && item.type === 'card')
            .length > 0 && component.type === 'map';
        if (!isCard) {
          component.layout = layoutId;
          component.region = region;
        } else {
          message.warning('Maps are not allowed in Cards.');
        }
      });
      return nextState;
    case 'POST_COMPONENT_INSERT':
      nextState = produce(state, (draftState) => {
        const { index, value } = action.data;
        draftState.key = makeId();
        value.id = makeId();
        draftState.components.splice(index + 1, 0, value);
      });
      return nextState;
    case 'POST_COMPONENT_ORDER':
      nextState = produce(state, (draftState) => {
        const { fromIndex, toIndex } = action.data;
        const item = draftState.components.splice(fromIndex, 1)[0];
        draftState.components.splice(toIndex, 0, item);
      });
      return nextState;
    case 'POST_COMPONENT_DATA_FILTER':
      return state;
    case 'POST_COMPONENT_SORT_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].sortOrder = value;
      });
      return nextState;
    case 'POST_COMPONENT_FILTER_ADD':
      nextState = produce(state, (draftState) => {
        if (!draftState.components[action.data.componentIndex].filters) {
          draftState.components[action.data.componentIndex].filters = [];
        }
        if (!draftState.components[action.data.componentIndex].filterOptions) {
          draftState.components[action.data.componentIndex].filterOptions = [];
        }
        draftState.components[action.data.componentIndex].filterOptions.push({
          exposed: false,
          type: 'dropwdown',
        });
        draftState.components[action.data.componentIndex].filters.push(['==']);
      });
      return nextState;
    case 'POST_COMPONENT_GLOBAL_FILTER_ADD':
      nextState = produce(state, (draftState) => {
        const componentsById = {};
        const layersById = {};
        draftState.components.forEach((component, key) => {
          componentsById[component.id] = key;
        });
        const { componentId, filters } = action.data;
        // if componentid has a slash this indicates a map component
        let componentIndex = componentId.includes('/')
          ? componentsById[componentId.split('/')[0]]
          : componentsById[componentId];
        let layerId = componentId.includes('/') ? componentId.split('/')[1] : undefined;

        if (draftState.components[componentIndex].layers) {
          draftState.components[componentIndex].layers.forEach((layer, key) => {
            layersById[layer.id] = key;
          });
          const layerIndex = layersById[layerId];

          if (draftState.components[componentIndex].layers[layerIndex].filters) {
            draftState.components[componentIndex].layers[layerIndex].filters = [
              ...draftState.components[componentIndex].layers[layerIndex].filters,
              ...filters,
            ];
          } else {
            draftState.components[componentIndex].layers[layerIndex].filters = filters;
          }
        } else {
          if (draftState.components[componentIndex].filters) {
            draftState.components[componentIndex].filters = [
              ...draftState.components[componentIndex].filters,
              ...filters,
            ];
          } else {
            draftState.components[componentIndex].filters = filters;
          }
        }
      });
      return nextState;
    case 'POST_COMPONENT_REMOVE_GLOBAL_FILTER':
      nextState = produce(state, (draftState) => {
        const componentsById = {};
        const layersById = {};
        draftState.components.forEach((component, key) => {
          componentsById[component.id] = key;
        });
        const { componentId, filters } = action.data;

        // refactor this to a utility function
        // if component still uses component indexes instead of ids

        // considers components use component ids instead of indexes
        let componentIdentifier = componentId;
        if (componentId.includes('/')) {
          componentIdentifier = componentId.split('/')[0];
        }
        if (Object.keys(componentsById).includes(componentIdentifier)) {
          let componentIndex = componentId.includes('/')
            ? componentsById[componentId.split('/')[0]]
            : componentsById[componentId];
          let layerId = componentId.includes('/') ? componentId.split('/')[1] : undefined;

          if (draftState.components[componentIndex]?.layers) {
            draftState.components[componentIndex].layers.forEach((layer, key) => {
              layersById[layer.id] = key;
            });
            const layerIndex = layersById[layerId];
            if (layerIndex) {
              const layerFilters = draftState.components[componentIndex].layers[layerIndex].filters;

              draftState.components[componentIndex].layers[layerIndex].filters =
                layerFilters.filter(
                  (filter) => !filters.map((filterItem) => filterItem.id).includes(filter.id)
                );
            }
          } else {
            const componentFilters = draftState.components[componentIndex]?.filters;
            draftState.components[componentIndex].filters = componentFilters.filter(
              (filter) => !filters.map((filterItem) => filterItem.id).includes(filter.id)
            );
          }
        }
      });
      return nextState;
    case 'POST_COMPONENT_LIST_WITH_GLOBAL_FILTER':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].componentsWithGlobalFilters =
          action.data.componentsWithGlobalFilters;
      });
      return nextState;
    case 'POST_COMPONENT_HIGHLIGHT_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].highlights[0][2] = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_FILTER_EDIT':
      nextState = produce(state, (draftState) => {
        const { filterIndex, componentIndex, layerIndex, propertyIndex, value } = action.data;
        const target = draftState.components[componentIndex];
        const filterObj = target?.filters?.[filterIndex];
        const layerFilterObj = target?.layers?.[layerIndex]?.filters?.[filterIndex];
        let property;
        if (target?.layers) {
          if (layerFilterObj) {
            target.layers[layerIndex].filters[filterIndex][propertyIndex] = value;
            property = target.layers[layerIndex].filters[filterIndex][1];
          }
        } else {
          if (filterObj) {
            target.filters[filterIndex][propertyIndex] = value;
            property = target.filters[filterIndex][1];
          }
        }

        // add filter change trigger event
        if (action.data.propertyIndex === 2 && action.data.value) {
          if (!draftState.events) {
            draftState.events = [];
          }
          draftState.events.unshift({ event: 'onFilterChange', action: action });
        }
        // add clear filter event
        /*
        if (action.data.propertyIndex === 2 && action.data.value === undefined) {
          if (!draftState.events) {
            draftState.events = []
          }
          draftState.events.unshift({ event: 'onClearFilter', action: action });
        }
        */

        if (target?.type !== 'filter') {
          // set inherited filter values
          draftState.components.forEach((component) => {
            if (component.filters && component.filters.length) {
              component.filters.forEach((filter) => {
                if (filter[1] === property && filter.inherit) {
                  filter[propertyIndex] = value;
                }
              });
            }
            if (component.layers) {
              component.layers.forEach((layer) => {
                if (layer.filters) {
                  layer.filters.forEach((filter) => {
                    if (filter[1] === property && filter.inherit) {
                      filter[propertyIndex] = value;
                    }
                  });
                }
              });
            }
          });
        } else {
          // set global filters values on components

          if (filterObj?.id) {
            draftState.components.forEach((component) => {
              // eslint-disable-next-line no-debugger
              if (component.filters && component.filters.length) {
                component.filters.forEach((filter) => {
                  if (filter['isGlobal'] && filter.id === filterObj.id) {
                    filter[propertyIndex] = value;
                  }
                  //  Allow setting of global filter value using inheri
                  if (filter[1] === property && filter?.inherit) {
                    filter[propertyIndex] = value;
                  }
                });
              }
              if (component.layers) {
                component.layers.forEach((layer) => {
                  if (layer.filters) {
                    layer.filters.forEach((filter) => {
                      if (filter.id === filterObj?.id && filter['isGlobal']) {
                        filter[propertyIndex] = value;
                      }
                      //  Allow setting of global filter value using inherit
                      if (filter[1] === property && filter?.inherit) {
                        filter[propertyIndex] = value;
                      }
                    });
                  }
                });
              }
            });
          }
        }
      });
      return nextState;
    case 'POST_COMPONENT_FILTER_OPTION_EDIT':
      nextState = produce(state, (draftState) => {
        const target = draftState.components[action.data.componentIndex];
        target.filterOptions[action.data.filterIndex][action.data.option] = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_FILTER_SET':
      nextState = produce(state, (draftState) => {
        draftState.components.forEach((component, index) => {
          if (index !== action.data.componentIndex) {
            if (component.filters) {
              component.lastUpdated = Number(moment().format('x'));
              component.filters.forEach((filter) => {
                if (filter[1] === action.data.filter) {
                  filter[2] = action.data.value;
                }
              });
            }
          }
        });
      });
      return nextState;
    case 'POST_COMPONENT_FILTER_MODE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].filterMode = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_FILTER_DELETE':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].filters.splice(action.data.index, 1);
      });
      return nextState;
    case 'POST_COMPONENT_FILTER_EXPOSE_EDIT':
      nextState = produce(state, (draftState) => {
        const target = draftState.components[action.data.componentIndex];
        if (!target.exposedFilters) {
          target.exposedFilters = {};
        }
        target.exposedFilters[action.data.filter] = {
          value: action.data.value,
          input: 'dropdown',
        };
      });
      return nextState;
    case 'POST_COMPONENT_FILTER_INPUT_EDIT':
      nextState = produce(state, (draftState) => {
        const target = draftState.components[action.data.componentIndex];
        target.exposedFilters[action.data.filter][action.data.field] = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_KEY_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.key = makeId();
      });
      return nextState;
    case 'POST_COMPONENT_WIDTH_EDIT':
      nextState = produce(state, (draftState) => {
        if (!draftState.components[action.data.componentIndex].width) {
          draftState.components[action.data.componentIndex].width = '';
        }
        draftState.key = makeId();
        draftState.components[action.data.componentIndex].width = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_SOURCE_GET':
      nextState = produce(state, (draftState) => {
        draftState.sources[action.data.uuid] = action.data;
      });
      return nextState;
    case 'POST_COMPONENT_FORMAT_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].columns[action.data.columnIndex].format =
          action.data.value || 'None';
      });
      return nextState;
    case 'POST_COMPONENT_HEIGHT_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].height = value;
      });
      return nextState;
    case 'POST_COMPONENT_PADDING_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].padding = value;
      });
      return nextState;
    case 'POST_COMPONENT_PER_ROW_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].perRow = value;
      });
      return nextState;
    case 'POST_COMPONENT_PAGINATION_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        const pagination = draftState.components[componentIndex].pagination;

        draftState.components[componentIndex].pagination = {
          ...pagination,
          ...value,
        };
      });
      return nextState;
    case 'POST_COMPONENT_SOURCE_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value, data } = action.data;
        if (!draftState.components[componentIndex].source) {
          draftState.components[componentIndex].source = '';
        }
        draftState.components[componentIndex].source = value;
        draftState.components[componentIndex].cube = data.cube;
      });
      return nextState;
    case 'POST_COMPONENT_LEGEND_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].legend = value;
      });
      return nextState;
    case 'POST_COMPONENT_TITLE_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].title = value;
      });
      return nextState;
    case 'POST_COMPONENT_STYLE_OPTION_SELECT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].styleOption = value;
      });
      return nextState;
    case 'POST_COMPONENT_CAPTION_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].caption = value;
      });
      return nextState;
    case 'POST_COMPONENT_COLS_EDIT':
      nextState = produce(state, (draftState) => {
        if (action.data.value !== '') {
          draftState.components[action.data.componentIndex].cols = action.data.value;
        } else {
          delete draftState.components[action.data.componentIndex].cols;
        }
      });
      return nextState;
    case 'POST_COMPONENT_ORDER_EDIT':
      nextState = produce(state, (draftState) => {
        const { currentIndex, newIndex } = action.data;
        const item = draftState.components[currentIndex];
        const target = draftState.components[newIndex];
        if (!target?.layout) {
          delete item?.layout;
          delete item?.region;
        }
        draftState.components.splice(currentIndex, 1);
        draftState.components.splice(newIndex, 0, item);
      });
      return nextState;
    case 'POST_COMPONENT_SWITCH_REGION_EDIT':
      nextState = produce(state, (draftState) => {
        const { currentIndex, newIndex } = action.data;
        const item = draftState.components[currentIndex];
        const target = draftState.components[newIndex];
        item.layout = target.layout;
        item.region = target.region;
        delete target.region;
        delete target.layout;
        draftState.components.splice(currentIndex, 1);
        draftState.components.splice(newIndex, 0, item);
      });
      return nextState;
    case 'POST_COMPONENT_MOVE_UP':
      nextState = produce(state, (draftState) => {
        const { componentIndex } = action.data;

        let lastComponent;
        const getNextIndex = () => {
          for (let i = 0; i < draftState.components.length; i++) {
            if (!draftState.components[i].region && i !== componentIndex) {
              lastComponent = i;
            }
            if (i === componentIndex) {
              return lastComponent;
            }
          }
        };

        let nextIndex = getNextIndex();
        if (nextIndex < 0) {
          nextIndex = 0;
        }

        const item = draftState.components[componentIndex];
        draftState.components.splice(componentIndex, 1);
        draftState.components.splice(nextIndex, 0, item);

        draftState.key = makeId();
      });
      return nextState;
    case 'POST_COMPONENT_MOVE_DOWN':
      nextState = produce(state, (draftState) => {
        const { componentIndex } = action.data;

        let nextComponent;
        const getNextIndex = () => {
          for (let i = 0; i < draftState.components.length; i++) {
            if (!draftState.components[i].region && i > componentIndex) {
              nextComponent = i;
              return nextComponent;
            }
          }
        };

        let nextIndex = getNextIndex();
        if (nextIndex < 0) {
          nextIndex = 0;
        }

        const item = draftState.components[action.data.componentIndex];

        if (nextIndex > draftState.components.length - 1) {
          nextIndex = draftState.components.length - 1;
        }

        draftState.components.splice(action.data.componentIndex, 1);
        draftState.components.splice(nextIndex, 0, item);

        draftState.key = makeId();
      });
      return nextState;

    //  POST COMPONENT IMAGE
    // ************************************************************
    case 'POST_COMPONENT_IMAGE_URL_EDIT':
      nextState = produce(state, (draftState) => {
        if (!draftState.components[action.data.componentIndex].imageUrl) {
          draftState.components[action.data.componentIndex].imageUrl = '';
        }
        draftState.components[action.data.componentIndex].imageUrl = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_IMAGE_HEIGHT_EDIT':
      nextState = produce(state, (draftState) => {
        if (!draftState.components[action.data.componentIndex].height) {
          draftState.components[action.data.componentIndex].height = '';
        }
        draftState.components[action.data.componentIndex].height = action.data.value;
      });
      return nextState;

    //  POST COMPONENT CHART
    // ************************************************************
    case 'POST_COMPONENT_CHART_TYPE_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].chartType = value;
      });
      return nextState;
    case 'POST_COMPONENT_CHART_SETTING_EDIT':
      nextState = produce(state, (draftState) => {
        const { property, value, componentIndex } = action.data;
        draftState.components[componentIndex][property] = value;
      });
      return nextState;
    case 'POST_COMPONENT_XAXIS_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].xAxis = value;
      });
      return nextState;
    case 'POST_COMPONENT_SERIES_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, seriesIndex, property, type } = action.data;
        draftState.components[componentIndex].series[seriesIndex][property] = action.data.value;
        draftState.components[componentIndex].series[seriesIndex][`${property}_type`] = type;
      });
      return nextState;
    case 'POST_COMPONENT_CHART_LIMIT_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].limit = Number(value);
      });
      return nextState;
    case 'POST_COMPONENT_SERIES_COLOR_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, seriesIndex, value } = action.data;
        draftState.components[componentIndex].series[seriesIndex].color = value;
      });
      return nextState;
    case 'POST_COMPONENT_LABELS_VISIBLE_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].hideLabels = value;
      });
      return nextState;
    case 'POST_COMPONENT_SERIES_DELETE':
      nextState = produce(state, (draftState) => {
        const { componentIndex, seriesIndex } = action.data;
        draftState.components[componentIndex].series.splice(seriesIndex, 1);
      });
      return nextState;
    case 'POST_COMPONENT_SERIES_ADD':
      nextState = produce(state, (draftState) => {
        const { componentIndex } = action.data;
        const item = {
          value: 'series',
          color: '#cccccc',
        };
        if (!draftState.components[componentIndex].series) {
          draftState.components[componentIndex].series = [];
        }
        draftState.components[componentIndex].series.push(item);
      });
      return nextState;
    case 'POST_COMPONENT_CHART_SORT_FIELD_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].sortField = value;
      });
      return nextState;
    case 'POST_COMPONENT_CHART_COLOR_FIELD_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].colorField = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_GROUP_BY_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].groupBy = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_GROUP_BY_COLORS_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].series[
          action.data.seriesIndex
        ].groupByColors = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_GROUP_BY_COLOR_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].series[
          action.data.seriesIndex
        ].groupByColors[action.data.colorIndex].color = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_SERIES_COLOR_MODE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].series[
          action.data.seriesIndex
        ].colorMode = action.data.value;
      });
      return nextState;
    //  POST COMPONENT MAP
    // ************************************************************
    case 'POST_COMPONENT_MAP_STORE_ADD':
      nextState = produce(state, (draftState) => {
        draftState.data[`map-${action.data.componentId}`] = action.data.map; // data for layer
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_BUILD_GEOJSON':
      nextState = produce(state, (draftState) => {
        draftState.data[`layer-${action.data.layer.id}`] = action.data.res; // data for layer
        draftState.data[`layer-geojson-${action.data.layer.id}`] = {
          type: 'FeatureCollection',
          features: [],
        };
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_FETCH_GEOJSON':
      nextState = produce(state, (draftState) => {
        draftState.data[action.data.id] = action.data.geojson;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_GEOJSON_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.data[`layer-geojson-${action.data.layerId}`] = action.data.geojson;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_TILESET_ID_EDIT':
      nextState = produce(state, (draftState) => {
        if (
          draftState.components[action.data.componentIndex] &&
          draftState.components[action.data.componentIndex].layers
        ) {
          if (action.data.childLayerIndex !== undefined) {
            draftState.components[action.data.componentIndex].layers[
              action.data.layerIndex
            ].children[action.data.childLayerIndex].tilesetId = action.data.value;
          } else {
            draftState.components[action.data.componentIndex].layers[
              action.data.layerIndex
            ].tilesetId = action.data.value;
          }
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_VISIBLE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].visible = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_MOVE':
      nextState = produce(state, (draftState) => {
        const layers = draftState.components[action.data.componentIndex].layers;
        const layerToMove = layers[action.data.parentLayerIndex];
        layers.splice(action.data.parentLayerIndex, 1);
        layers.splice(action.data.nextIndex, 0, layerToMove);
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_VISIBLE_EDIT':
      nextState = produce(state, (draftState) => {
        const target = draftState.components[action.data.componentIndex]?.layers;
        const layer = target?.[action.data.layerIndex];
        const layerId = layer?.id;
        // check if layer is in layer group
        if (layer && target && layerId) {
          target.forEach((item) => {
            if (item.layerGroup === layerId) {
              item.visible = action.data.value;
            }
          });
          // legacy :: check if layer is in children
          if (layer.children) {
            layer.children.forEach((item) => {
              item.visible = action.data.value;
            });
          }
          layer.visible = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_TOGGLE_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        target.hasToggle = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_MENU_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        target.hasMenuItem = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_SELECTOR_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layerSelector = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LABEL_DELETE':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers[action.data.index].labels.splice(
          action.data.index,
          1
        );
      });
      return nextState;
    case 'POST_COMPONENT_MAP_FILTER_SET':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].activeValue = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_FILTER_INPUT_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.exposedFilters[action.data.filter][action.data.field] = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].exposedFilters[action.data.filter][
            action.data.field
          ] = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_FILTER_OPTION_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.filterOptions[action.data.filterIndex][action.data.option] = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].filterOptions[action.data.filterIndex][
            action.data.option
          ] = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_FILTER_SET':
      // LAYERS
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers.forEach((layer) => {
          if (layer.filters) {
            layer.filters.forEach((filter) => {
              if (filter[1] === action.data.filter) {
                filter[2] = action.data.value;
              }
            });
          }
        });

        // CHILD LAYERS
        draftState.components[action.data.componentIndex].layers.forEach((layer) => {
          if (layer.children) {
            layer.children.forEach((child) => {
              if (child.filters) {
                child.filters.forEach((filter) => {
                  if (filter[1] === action.data.filter) {
                    filter[2] = action.data.value;
                  }
                });
              }
            });
          }
        });
        // CHILD LAYER LABELS
        draftState.components[action.data.componentIndex].layers.forEach((layer) => {
          if (layer.children) {
            layer.children.forEach((child) => {
              if (child.labels) {
                child.labels.forEach((label) => {
                  if (label.filters) {
                    label.filters.forEach((filter) => {
                      if (filter[1] === action.data.filter) {
                        filter[2] = action.data.value;
                      }
                    });
                  }
                });
              }
            });
          }
        });
        // LAYER LABELS
        draftState.components[action.data.componentIndex].layers.forEach((layer) => {
          if (layer.labels) {
            layer.labels.forEach((label) => {
              if (label.filters) {
                label.filters.forEach((filter) => {
                  if (filter[1] === action.data.filter) {
                    filter[2] = action.data.value;
                  }
                });
              }
            });
          }
        });
        // OTHER COMPONENTS...
        draftState.components.forEach((component) => {
          if (component.filters) {
            component.lastUpdated = Number(moment().format('x'));
            component.filters.forEach((filter) => {
              if (filter[1] === action.data.filter) {
                filter[2] = action.data.value;
              }
            });
          }
          if (component.type === 'chart') {
            component.date = action.data.value;
          }
        });
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_DATA_FILTER':
      return state;
    case 'POST_COMPONENT_MAP_LAYER_FILTER_ADD':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          if (!target.filters) {
            target.filters = [];
          }
          if (!target.filterOptions) {
            target.filterOptions = [];
          }
          target.filters.push(['==', undefined, undefined]);
          target.filterOptions.push({ exposed: false, type: 'dropwdown' });
        } else {
          if (!target.children[action.data.childLayerIndex].filters) {
            target.children[action.data.childLayerIndex].filters = [];
          }
          if (!target.children[action.data.childLayerIndex].filterOptions) {
            target.children[action.data.childLayerIndex].filterOptions = [];
          }
          target.children[action.data.childLayerIndex].filters.push(['==', undefined, undefined]);
          target.children[action.data.childLayerIndex].filterOptions.push({
            exposed: false,
            type: 'dropwdown',
          });
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_POPUP_ENABLE':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          if (!target.hasPopup) {
            target.hasPopup = true;
          }
          target.hasPopup = action.data.value;
        } else {
          if (!target.children[action.data.childLayerIndex].hasPopup) {
            target.hasPopup = true;
          }
          target.children[action.data.childLayerIndex].hasPopup = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_POPUP_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          if (!target.popupText) {
            target.popupText = '';
          }
          target.popupText = action.data.value;
        } else {
          if (!target.children[action.data.childLayerIndex].popupText) {
            target.popupText = '';
          }
          target.children[action.data.childLayerIndex].popupText = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_FILTER_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        // parent layer
        if (action.data.childLayerIndex === undefined) {
          target.filters[action.data.filterIndex][action.data.propertyIndex] = action.data.value;
          const targetFilter = target.filters[action.data.filterIndex];
          const operator = targetFilter[0];
          const property = targetFilter[1];
          const value = targetFilter[2];
          const layerId = target.id;
          // update other layers
          draftState.components[action.data.componentIndex].layers.forEach((item) => {
            if (item.filters) {
              item.filters.forEach((filter) => {
                if (
                  filter[1] === property &&
                  filter[0] === operator &&
                  item.filter &&
                  item.filter?.inherit === true
                ) {
                  filter[2] = value;
                }
              });
            }
            // update grouped layers
            if (item.layerGroup) {
              if (item.layerGroup === layerId) {
                if (item.filters) {
                  item.filters.forEach((filter) => {
                    if (
                      filter[1] === property &&
                      filter[0] === operator &&
                      filter?.inherit === true
                    ) {
                      filter[2] = value;
                    }
                  });
                }
              }
            }

            // update child layers
            if (item.children) {
              item.children.forEach((layer) => {
                if (layer.filters) {
                  layer.filters.forEach((filter) => {
                    if (
                      filter[1] === property &&
                      filter[0] === operator &&
                      filter?.inherit === true
                    ) {
                      filter[2] = value;
                    }
                  });
                }
              });
            }
          });
          // update other components
          draftState.components.forEach((item) => {
            if (item.filters) {
              item.filters.forEach((filter) => {
                if (filter[1] === property && filter[0] === operator && filter?.inherit === true) {
                  filter[2] = value;
                }
              });
            }
            // set inherited filter values on layers
            if (item.layers) {
              item.layers.forEach((layer) => {
                if (layer.filters) {
                  layer.filters.forEach((filter, index) => {
                    if (filter[1] === property && filter?.inherit === true) {
                      filter[2] = value;
                    }
                  });
                }
              });
            }
          });
          // child layer
        } else {
          target.children[action.data.childLayerIndex].filters[action.data.filterIndex][
            action.data.propertyIndex
          ] = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_FILTER_MODE_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.filterMode = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].filterMode = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_FILTER_DELETE':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.filters.splice(action.data.index, 1);
          if (target.filterOptions) {
            target.filterOptions.splice(action.data.index, 1);
          }
        } else {
          if (target.children[action.data.childLayerIndex].exposedFilters) {
            delete target.children[action.data.childLayerIndex].exposedFilters[
              target.children[action.data.childLayerIndex].filters[action.data.index][1]
            ];
          }
          target.children[action.data.childLayerIndex].filters.splice(action.data.index, 1);
          target.children[action.data.childLayerIndex].filterOptions.splice(action.data.index, 1);
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_SOURCE_EDIT':
      nextState = produce(state, (draftState) => {
        //draftState.components[action.data.componentIndex].key = makeId();
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.source = action.data.value;
          target.geometries = action.data.data.geometries;
          target.columns = action.data.data.columns;
          target.dimensions = [];
          target.measures = [];
          draftState.sources[action.data.value] = action.data.data;
        } else {
          target.children[action.data.childLayerIndex].source = action.data.value;
          target.children[action.data.childLayerIndex].geometries = action.data.data.geometries;
          target.children[action.data.childLayerIndex].columns = action.data.data.columns;
          draftState.sources[action.data.value] = action.data.data;
          target.children[action.data.childLayerIndex].dimensions = [];
          target.children[action.data.childLayerIndex].measures = [];
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_GEOMETRY_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].key = makeId();
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.geometryIndex = action.data.value;
          target.geometry = action.data.geometry;
        } else {
          target.children[action.data.childLayerIndex].geometryIndex = action.data.value;
          target.children[action.data.childLayerIndex].geometry = action.data.geometry;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_COLOR_MODE_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.colorMode = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].colorMode = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_TYPE_EDIT':
      nextState = produce(state, (draftState) => {
        //draftState.components[action.data.componentIndex].key = makeId();
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.layerType = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].layerType = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_PROPERTY_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target[action.data.property] = action.data.value;
        } else {
          target.children[action.data.childLayerIndex][action.data.property] = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_FILL_COLOR_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.fillColor = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].fillColor = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_CIRCLE_COLOR_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.circleColor = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].circleColor = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_CIRCLE_RADIUS_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.circleRadius = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].circleRadius = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_PICK_MAP_STYLE_VALUE':
      nextState = produce(state, (draftState) => {
        const { data, mapProperty } = action;
        const target = draftState.components[data.componentIndex].layers[data.layerIndex];
        if (data.childLayerIndex === undefined) {
          target[mapProperty] = data.value;
        } else {
          target.children[action.data.childLayerIndex][mapProperty] = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_ICON_SIZE_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.iconSize = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].iconSize = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_ICON_ALLOW_OVERLAP':
      nextState = produce(state, (draftState) => {
        const { layerIndex, childLayerIndex, componentIndex, value } = action.data;

        let target = draftState.components[componentIndex].layers[layerIndex];

        if (childLayerIndex !== undefined) {
          target = target.children[childLayerIndex];
        }

        target.iconAllowOverlap = value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_CIRCLE_SCALE_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.circleScale = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].circleScale = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LINE_COLOR_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.lineColor = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].lineColor = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LABEL_FIELD_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers[action.data.layerIndex].labels[
          action.data.labelIndex
        ].symbolField = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LABEL_PROPERTY_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.labels[action.data.labelIndex][action.data.property] = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].labels[action.data.labelIndex][
            action.data.property
          ] = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LABEL_COLOR_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers[action.data.layerIndex].labels[
          action.data.labelIndex
        ].symbolColor = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LABEL_SIZE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers[action.data.layerIndex].labels[
          action.data.labelIndex
        ].symbolSize = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LABEL_PREFIX_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers[action.data.layerIndex].labels[
          action.data.labelIndex
        ].symbolPrefix = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LABEL_SUFFIX_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers[action.data.layerIndex].labels[
          action.data.labelIndex
        ].symbolSuffix = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LABEL_OFFSET_X_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers[action.data.layerIndex].labels[
          action.data.labelIndex
        ].symbolOffsetX = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LABEL_OFFSET_Y_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers[action.data.layerIndex].labels[
          action.data.labelIndex
        ].symbolOffsetY = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_COLOR_STEP_ADD':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          if (!target.colorSteps) {
            target.colorSteps = [];
          }
          target.colorSteps.push(action.data.value);
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_COLOR_STEP_DELETE':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.colorSteps.splice(action.data.value, 1);
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_COLOR_STEP_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].key = makeId();
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.colorSteps[action.data.stepIndex] = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_COLOR_CATEGORY_ADD':
      nextState = produce(state, (draftState) => {
        const { componentIndex, layerIndex, childLayerIndex, value } = action.data;
        draftState.components[action.data.componentIndex].key = makeId();
        let target = draftState.components[componentIndex].layers[layerIndex];

        if (childLayerIndex !== undefined) {
          target = target.children[childLayerIndex];
        }

        if (!target.colorCategories) {
          target.colorCategories = [];
        }

        target.colorCategories.push(value);
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_COLOR_CATEGORY_DELETE':
      nextState = produce(state, (draftState) => {
        const { componentIndex, layerIndex, childLayerIndex, value } = action.data;

        let target = draftState.components[componentIndex].layers[layerIndex];

        if (childLayerIndex !== undefined) {
          target = target.children[childLayerIndex];
        }

        target.colorCategories.splice(value, 1);
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_COLOR_CATEGORY_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, layerIndex, childLayerIndex, stepIndex, value } = action.data;

        let target = draftState.components[componentIndex].layers[layerIndex];

        if (childLayerIndex !== undefined) {
          target = target.children[childLayerIndex];
        }

        target.colorCategories[stepIndex] = value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_SYMBOL_CATEGORY_ADD':
      nextState = produce(state, (draftState) => {
        const { componentIndex, layerIndex, childLayerIndex, value } = action.data;

        let target = draftState.components[componentIndex].layers[layerIndex];

        if (childLayerIndex !== undefined) {
          target = target.children[childLayerIndex];
        }

        if (!target.symbolCategories) {
          target.symbolCategories = [];
        }

        target.symbolCategories.push(value);
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_SYMBOL_CATEGORY_DELETE':
      nextState = produce(state, (draftState) => {
        const { componentIndex, layerIndex, childLayerIndex, value } = action.data;

        let target = draftState.components[componentIndex].layers[layerIndex];

        if (childLayerIndex !== undefined) {
          target = target.children[childLayerIndex];
        }

        target.symbolCategories.splice(value, 1);
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_SYMBOL_CATEGORY_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, layerIndex, childLayerIndex, stepIndex, value } = action.data;

        let target = draftState.components[componentIndex].layers[layerIndex];

        if (childLayerIndex !== undefined) {
          target = target.children[childLayerIndex];
        }

        target.symbolCategories[stepIndex] = value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_COLORS_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.colorScheme = action.data.value;
          target.colorsArray = action.data.colors;
        } else {
          target.children[action.data.childLayerIndex].colorScheme = action.data.value;
          target.children[action.data.childLayerIndex].colorsArray = action.data.colors;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_STOPS_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers[action.data.index].stops =
          action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_STOP_COUNT_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].layers[action.data.index].stopCount =
          action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_STOP_VALUE_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.stops[action.data.stopIndex] = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].stops[action.data.stopIndex] =
            action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_STOP_COLOR_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.colorsArray[action.data.stopIndex] = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].colorsArray[action.data.stopIndex] =
            action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LINE_WIDTH_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.lineWidth = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].lineWidth = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_DATA_FIELD_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].key = makeId();
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target[action.data.property] = action.data.value;
          if (action.data.fieldType === 'string') {
            target.colorMode = 'category';
          } else {
            target.colorMode = 'steps';
          }
        } else {
          target.children[action.data.childLayerIndex][action.data.property] = action.data.value;
          if (action.data.fieldType === 'string') {
            target.children[action.data.childLayerIndex].colorMode = 'category';
          } else {
            target.children[action.data.childLayerIndex].colorMode = 'steps';
          }
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_DATA_FIELD_INDEX_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target[action.data.property] = action.data.value;
          if (target.circleColorField) {
            target.circleColorField = target[action.data.field][action.data.value];
          }
          // Update label value
          if (target.labels) {
            target.labels.forEach((label) => {
              if (target[action.data.field].includes(label.symbolField)) {
                label.symbolField = target[action.data.field][action.data.value];
              }
            });
          }
          // update chart components
          draftState.components.forEach((component) => {
            if (component.type === 'chart') {
              if (component.series) {
                component.series.forEach((series) => {
                  series.value = target[action.data.field][action.data.value];
                });
              }
            }
          });
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_GROUP_NAME_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.parentLayerIndex];
        target.layerGroupName = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_NAME_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.parentLayerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.layerName = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].layerName = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_BEFORE_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.layerBefore = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].layerBefore = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_LEGEND_EDIT':
      nextState = produce(state, (draftState) => {
        const layer =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        layer.legend = {
          ...layer.legend,
          position: action.data.position ? action.data.position : layer.legend.position,
          isEnabled:
            action.data.isEnabled !== undefined ? action.data.isEnabled : layer.legend.isEnabled,
        };
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_DELETE':
      nextState = produce(state, (draftState) => {
        const { componentIndex, childLayerIndex, parentLayerIndex } = action.data;
        const target = draftState.components[componentIndex].layers;
        let layer = target[parentLayerIndex];

        if (action.data.childLayerIndex === undefined) {
          target.splice(parentLayerIndex, 1);
        } else {
          layer = target[parentLayerIndex].children[childLayerIndex];
          target[parentLayerIndex].children.splice(childLayerIndex, 1);
        }

        draftState.components[componentIndex].layers = resetLayersPosition(
          draftState.components[componentIndex].layers,
          getMapLayerId(layer)
        );
      });

      return nextState;
    case 'POST_COMPONENT_MAP_LABEL_ADD':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          if (!target.labels) {
            target.labels = [];
          }
          target.labels.push({
            id: makeId(),
            symbolField: '',
            symbolPrefix: '',
            symbolSuffix: '',
          });
        } else {
          if (!target.children[action.data.childLayerIndex].labels) {
            target.children[action.data.childLayerIndex].labels = [];
          }
          target.children[action.data.childLayerIndex].labels.push({
            id: makeId(),
            symbolField: '',
            symbolPrefix: '',
            symbolSuffix: '',
          });
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LABEL_GEOMETRY_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.labels[action.data.labelIndex].geometryIndex = action.data.value;
          target.labels[action.data.labelIndex].geometry = action.data.geometry;
          target.labels[action.data.labelIndex].source = target.source;
          target.labels[action.data.labelIndex].filters = target.filters;
          target.labels[action.data.labelIndex].filterMode = target.filterMode;
        } else {
          target.children[action.data.childLayerIndex].labels[
            action.data.labelIndex
          ].geometryIndex = action.data.value;
          target.children[action.data.childLayerIndex].labels[action.data.labelIndex].geometry =
            action.data.geometry;
          target.children[action.data.childLayerIndex].labels[action.data.labelIndex].source =
            target.children[action.data.childLayerIndex].source;
          target.children[action.data.childLayerIndex].labels[action.data.labelIndex].filters =
            target.children[action.data.childLayerIndex].filters;
          target.children[action.data.childLayerIndex].labels[action.data.labelIndex].filterMode =
            target.children[action.data.childLayerIndex].filterMode;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_ZOOM_VISIBLE_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.minMaxZoom = action.data.value;
          if (target.labels) {
            target.labels.forEach((label) => {
              label.minMaxZoom = action.data.value;
            });
          }
        } else {
          target.children[action.data.childLayerIndex].minMaxZoom = action.data.value;
          // update label minMaxZoom
          if (target.children[action.data.childLayerIndex].labels) {
            target.children[action.data.childLayerIndex].labels.forEach((label) => {
              label.minMaxZoom = target.children[action.data.childLayerIndex].minMaxZoom;
            });
          }
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LABEL_FIELD_EDIT':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.labels[action.data.labelIndex].symbolField = action.data.value;
        } else {
          target.children[action.data.childLayerIndex].labels[action.data.labelIndex].symbolField =
            action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LABEL_FONT_SIZE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].labels[action.data.labelIndex].fontSize =
          action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LABEL_PREFIX_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].labels[action.data.labelIndex].prefix =
          action.data.value;
        if (!action.data.value) {
          delete draftState.components[action.data.componentIndex].labels[action.data.labelIndex]
            .prefix;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LABEL_SUFFIX_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].labels[action.data.labelIndex].suffix =
          action.data.value;
        if (!action.data.value) {
          delete draftState.components[action.data.componentIndex].labels[action.data.labelIndex]
            .suffix;
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LABEL_X_OFFSET_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].labels[action.data.labelIndex].xOffset =
          action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LABEL_Y_OFFSET_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].labels[action.data.labelIndex].yOffset =
          action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LABEL_DELETE':
      nextState = produce(state, (draftState) => {
        const target =
          draftState.components[action.data.componentIndex].layers[action.data.layerIndex];
        if (action.data.childLayerIndex === undefined) {
          target.labels.splice(action.data.labelIndex, 1);
        } else {
          target.children[action.data.childLayerIndex].labels.splice(action.data.labelIndex, 1);
        }
      });
      return nextState;
    case 'POST_COMPONENT_MAP_SCALE_BAR_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].scaleBar = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_CLUSTERS_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].columns[
          action.data.columnIndex
        ].colorClusterCount = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_COLOR_KEY_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].columns[
          action.data.columnIndex
        ].colorKey = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_COLOR_TYPE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].properties[
          action.data.columnIndex
        ].colorType = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_COLORS_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].columns[action.data.columnIndex].colors =
          action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_COLOR_EDIT':
      nextState = produce(state, (draftState) => {
        const { data } = action;
        const { componentIndex, columnIndex, value, fieldIndex } = data;

        if (!draftState.components[componentIndex].properties[columnIndex].colors) {
          draftState.components[componentIndex].properties[columnIndex].colors = [];
        }
        draftState.components[componentIndex].properties[columnIndex].colors[fieldIndex] = value;
      });
      return nextState;

    case 'POST_COMPONENT_COLOR_REMOVE':
      nextState = produce(state, (draftState) => {
        const { data } = action;
        const { componentIndex, columnIndex, fieldIndex } = data;

        draftState.components[componentIndex].properties[columnIndex].colors.splice(fieldIndex, 1);
      });

      return nextState;
    case 'POST_COMPONENT_BREAKS_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].columns[action.data.columnIndex].breaks =
          action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_BREAK_EDIT':
      nextState = produce(state, (draftState) => {
        const { data } = action;
        const { componentIndex, columnIndex, value, fieldIndex } = data;

        if (!draftState.components[componentIndex].properties[columnIndex].breaks) {
          draftState.components[componentIndex].properties[columnIndex].breaks = [];
        }
        draftState.components[componentIndex].properties[columnIndex].breaks[fieldIndex] = value;
      });
      return nextState;

    case 'POST_COMPONENT_BREAK_REMOVE':
      nextState = produce(state, (draftState) => {
        const { data } = action;
        const { componentIndex, columnIndex, fieldIndex } = data;

        draftState.components[componentIndex].properties[columnIndex].breaks.splice(fieldIndex, 1);
      });

      return nextState;
    case 'POST_COMPONENT_CATEGORIES_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].columns[
          action.data.columnIndex
        ].categories = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_CATEGORY_EDIT':
      nextState = produce(state, (draftState) => {
        const { data } = action;
        const { componentIndex, columnIndex, fieldIndex, value } = data;

        if (!draftState.components[componentIndex].properties[columnIndex].categories) {
          draftState.components[componentIndex].properties[columnIndex].categories = [];
        }
        draftState.components[componentIndex].properties[columnIndex].categories[fieldIndex] =
          value;
      });
      return nextState;
    case 'POST_COMPONENT_CATEGORY_REMOVE':
      nextState = produce(state, (draftState) => {
        const { data } = action;
        const { componentIndex, columnIndex, fieldIndex } = data;

        draftState.components[componentIndex].properties[columnIndex].categories.splice(
          fieldIndex,
          1
        );
      });
      return nextState;
    case 'POST_COMPONENT_DISPLAY_ZERO_VALUES_EDIT':
      nextState = produce(state, (draftState) => {
        const { data } = action;
        const { componentIndex, columnIndex, value } = data;

        draftState.components[componentIndex].properties[columnIndex].displayZeroValues = value;
      });
      return nextState;

    case 'POST_COMPONENT_COLORS_REVERSE':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].columns[
          action.data.columnIndex
        ].colors.reverse();
      });
      return nextState;
    case 'POST_COMPONENT_LONGITUDE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].longitude = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_LATITUDE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].latitude = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_ZOOM_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].zoom = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_BEARING_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].bearing = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_PITCH_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].pitch = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_STYLE_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;

        draftState.components[componentIndex].mapStyle = value;

        // for each layer reset any layerBefore property set
        draftState.components[componentIndex].layers = resetLayersPosition(
          draftState.components[componentIndex].layers
        );
      });
      return nextState;
    case 'POST_COMPONENT_MAP_STYLE_COLOR_EDIT':
      nextState = produce(state, (draftState) => {
        const { componentIndex, value } = action.data;
        draftState.components[componentIndex].styleColor = value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_TYPE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].mapType = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_HEIGHT_EDIT':
      nextState = produce(state, (draftState) => {
        if (!draftState.components[action.data.componentIndex].height) {
          draftState.components[action.data.componentIndex].height = '';
        }
        draftState.components[action.data.componentIndex].height = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_STYLE_GET':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].styleLayers = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_GEOMETRY_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].columns[
          action.data.columnIndex
        ].geometryIndex = action.data.value;
        draftState.components[action.data.componentIndex].columns[
          action.data.columnIndex
        ].geometryId = action.data.geometryId;
      });
      return nextState;
    case 'POST_COMPONENT_CIRCLE_COLOR_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].columns[action.data.columnIndex].color =
          action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_CIRCLE_RADIUS_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].columns[
          action.data.columnIndex
        ].maxRadius = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_MAP_LAYER_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].activeValue = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_COLORS_APPLY_ALL':
      nextState = produce(state, (draftState) => {
        const colors =
          draftState.components[action.data.componentIndex].columns[action.data.columnIndex].colors;
        const breaks =
          draftState.components[action.data.componentIndex].columns[action.data.columnIndex].breaks;
        const categories =
          draftState.components[action.data.componentIndex].columns[action.data.columnIndex]
            .categories;
        const geometryIndex =
          draftState.components[action.data.componentIndex].columns[action.data.columnIndex]
            .geometryIndex;
        const colorClusterCount =
          draftState.components[action.data.componentIndex].columns[action.data.columnIndex]
            .colorClusterCount;
        const colorType =
          draftState.components[action.data.componentIndex].columns[action.data.columnIndex]
            .colorType;
        draftState.components[action.data.componentIndex].columns.forEach((column) => {
          column.colors = colors;
          column.breaks = breaks;
          column.categories = categories;
          column.geometryIndex = geometryIndex;
          column.colorClusterCount = colorClusterCount;
          column.colorType = colorType;
        });
      });
      return nextState;
    case 'POST_COMPONENT_DEFAULT_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].default = action.data.value;
      });
      return nextState;

    //  POST COMPONENT TABLE
    // ************************************************************
    case 'POST_COMPONENT_COLUMN_EDIT':
      nextState = produce(state, (draftState) => {
        const property = action.data.property;
        if (
          !draftState.components[action.data.componentIndex].columns[action.data.columnIndex][
            property
          ]
        ) {
          draftState.components[action.data.componentIndex].columns[action.data.columnIndex][
            property
          ] = action.data.value;
        }
        draftState.components[action.data.componentIndex].columns[action.data.columnIndex][
          property
        ] = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_TABLE_SORT_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].sort = action.data.value;
      });
      return nextState;

    // POST NUMBER COMPONENT
    // ************************************************************
    case 'POST_COMPONENT_CONTEXT_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].context = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_DATA_FIELD_ADD':
      nextState = produce(state, (draftState) => {
        if (!draftState.components[action.data.componentIndex][action.data.field]) {
          draftState.components[action.data.componentIndex][action.data.field] = [];
        }
        draftState.components[action.data.componentIndex][action.data.field].push({
          property: '',
          value: '',
        });
      });
      return nextState;
    case 'POST_COMPONENT_DATA_FIELD_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex][action.data.field][action.data.itemIndex][
          action.data.property
        ] = action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_DATA_FIELD_DELETE':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex][action.data.field].splice(
          action.data.itemIndex,
          1
        );
      });
      return nextState;

    // POST LAYOUT COMPONENT
    // ************************************************************
    case 'POST_COMPONENT_LAYOUT_EDIT':
      nextState = produce(state, (draftState) => {
        const type = draftState.components[action.data.componentIndex].type;
        if (type === 'card') {
          draftState.components[action.data.componentIndex].cardLayout = action.data.value;
        } else {
          draftState.components[action.data.componentIndex].layoutId = action.data.value;
        }
      });
      return nextState;
    case 'POST_COMPONENT_LAYOUT_TAB_ADD':
      nextState = produce(state, (draftState) => {
        if (!draftState.components[action.data.componentIndex].tabs) {
          draftState.components[action.data.componentIndex].tabs = [];
        }
        draftState.components[action.data.componentIndex].tabs.push({
          title: 'Tab',
        });
      });
      return nextState;
    case 'POST_COMPONENT_LAYOUT_TAB_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].tabs[action.data.tabIndex].title =
          action.data.value;
      });
      return nextState;
    case 'POST_COMPONENT_LAYOUT_TAB_DELETE':
      nextState = produce(state, (draftState) => {
        draftState.components[action.data.componentIndex].tabs.splice(action.data.tabIndex, 1);
      });
      return nextState;
    case 'POST_COMPONENT_ERRORS_ADD':
      nextState = produce(state, (draftState) => {
        const { id, type, name, errors } = action.data;
        if (!draftState.errors) {
          draftState.errors = {};
        }
        draftState.errors[id] = {
          id,
          name,
          type,
          errors,
        };
      });
      return nextState;
    case 'POST_COMPONENT_ERRORS_DELETE':
      nextState = produce(state, (draftState) => {
        const { id } = action.data;
        delete draftState.errors[id];
      });
      return nextState;
    default:
      return state;
  }
};

export default post;
