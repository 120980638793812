
export const table = [
  {
    type: 'font',
    value: 'tableFontFamily',
    label: 'Font Family',
    placeholder: 'Poppins'
  },
  {
    type: 'color',
    value: 'colorText',
    label: 'Text color',
    defaultValue: '#111111',
  },
  {
    type: 'color',
    value: 'headerColor',
    label: 'Header text color',
    defaultValue: '#111111',
  },
  {
    type: 'color',
    value: 'headerBg',
    label: 'Header bg color',
    defaultValue: '#eeeeee'
  },
  {
    type: 'color',
    value: 'headerSortActiveBg',
    label: 'Header bg active',
    defaultValue: '#dddddd'
  },
  {
   type: 'color',
   value: 'headerSortHoverBg',
   label: 'Header bg hover',
   defaultValue: '#eeeeee'
  },
  {
    type: 'color',
    value: 'headerSplitColor',
    label: 'Header split color',
    defaultValue: '#ffffff'
  },
  {
    type: 'color',
    value: 'colorBgContainer',
    label: 'Background',
    defaultValue: '#eeeeee',
  },
  {
    type: 'color',
    value: 'tableBorderColor',
    label: 'Border Color',
    defaultValue: '#eeeeee'
  },
  {
    type: 'number',
    value: 'cellFontSize',
    label: 'Font size',
    placeholder: 12,
  },
  {
    type: 'number',
    value: 'cellPaddingBlock',
    label: 'Cell padding',
    placeholder: 10
  }
]