import React, { Component, Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, message } from 'antd';
import { TitleComponent } from '../../Title';
import { TextComponent } from '../../Text';
import { ReferenceComponent } from '../../Reference';
import { Chart as ChartComponent } from '../../Chart';
import MapComponent from '../../Map';
import { Table as TableComponent } from '../../Table';
import { Image as ImageComponent } from '../../Image';
import { LayoutContainer as LayoutComponent } from '../../Layout';
import { Card as CardComponent } from '../../Card';
import { BigNumber as NumberComponent } from '../../Number';
import { Filter as FilterComponent } from '../../Filter';
import { HtmlComponent } from '../../Html';
import { ButtonComponent } from '../../Button';
import {
  actionConfigComponentIndexActive,
  actionConfigActiveLayerEdit,
  actionConfigActiveColumnEdit,
} from '../../actions';
import { AddComponentMenu } from '../../helpers/Settings/add-component-menu';

const NotebookTemplate = (props) => {
  const post = useSelector((store) => store.post);
  const user = useSelector((store) => store.user);
  const space = useSelector((store) => store.space);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { embedComponents, embed } = props;

  const addActiveClass = (id) => {
    const el = document.getElementById(`component-tree-${id}`);
    if (el) {
      el?.classList.add('active');
    }
  };

  const removeActiveClass = (id) => {
    const el = document.getElementById(`component-tree-${id}`);
    if (el) {
      el?.classList.remove('active');
    }
  };

  const setComponentActive = (e, index) => {
    e.stopPropagation();
    dispatch(
      actionConfigComponentIndexActive({
        value: index,
      })
    );
    dispatch(
      actionConfigActiveLayerEdit({
        value: -1,
      })
    );
    dispatch(
      actionConfigActiveColumnEdit({
        value: -1,
      })
    );
  };

  return (
    <div
      className={`post-page`}
      style={{
        background: post?.config?.backgroundColor,
      }}
    >
      <div className='post--content'>
        <Row gutter={20}>
          {!loading &&
            post.components &&
            post.components.map(
              (item, index) =>
                !item.region &&
                (embed ? embedComponents.includes(item.id) : true) &&
                item.hidden !== true && (
                  <Fragment key={index}>
                    {item.type === 'title' && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <TitleComponent
                          key={index}
                          componentId={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'text' && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <TextComponent
                          key={item.id}
                          componentId={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'chart' && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <ChartComponent
                          key={item.id}
                          componentId={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'map' && (
                      <Col
                        span={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                      >
                        <MapComponent
                          key={item.id}
                          componentId={item.id}
                          index={index}
                        />
                      </Col>
                    )}
                    {item.type === 'table' && (
                      <Col
                        span={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <TableComponent
                          key={item.id}
                          componentId={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'card' && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <CardComponent
                          key={item.id}
                          componentId={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'image' && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <ImageComponent
                          componentId={item.id}
                          key={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'layout' && !item.spaceComponent && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <LayoutComponent
                          componentId={item.id}
                          key={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'number' && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <NumberComponent
                          componentId={item.id}
                          key={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'html' && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <HtmlComponent
                          componentId={item.id}
                          key={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'reference' && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <ReferenceComponent
                          componentId={item.id}
                          key={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'filter' && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <FilterComponent
                          componentId={item.id}
                          key={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                    {item.type === 'button' && !item.spaceComponent && (
                      <Col
                        xs={24}
                        onMouseOver={() => addActiveClass(item.id)}
                        onMouseOut={() => removeActiveClass(item.id)}
                        onClick={(e) => setComponentActive(e, index)}
                      >
                        <ButtonComponent
                          componentId={item.id}
                          key={item.id}
                          componentIndex={index}
                        />
                      </Col>
                    )}
                  </Fragment>
                )
            )}
        </Row>
      </div>
    </div>
  );
};

export { NotebookTemplate };
