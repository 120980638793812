import React from 'react';
import { formatValue } from '../helpers';
import { genericDateFormatter } from '../utils';
import moment from 'moment';

export const getGrid = (component, showGrid) => {
  if (component?.gridLines || showGrid === false) {
    return {
      line: {
        style: {
          lineWidth: 0,
        },
      },
    };
  } else {
    return {
      line: {
        style: {
          lineWidth: 1,
          stroke: '#DBDBDB',
        },
      },
    };
  }
};

export const getCardSource = (dataRow, property) => {
  let cardSource;
  Object.keys(dataRow).forEach((key) => {
    const split = key.split('.');
    if (split[1] === property) {
      cardSource = split[0];
    }
  });
  return cardSource;
};

export const getScale = (component) => {
  const {
    yAxisMin,
    yAxisMax,
    seriesTickCount,
    categoryTickCount,
    xAxisMin,
    xAxisMax,
    xAxis,
    series,
    customToolTipProperties,
    bubbleProperties,
    showPercentageChart,
    chartType,
  } = component;
  let scaleType = undefined;
  const chartTypes = new Set(['line', 'area', 'pie', 'areaStack']);
  if (chartTypes.has(chartType)) {
    if (xAxis?.value && xAxis?.dataType) {
      if (xAxis?.dataType === 'time') {
        scaleType = 'time';
      } else if (xAxis?.dataType === 'number') {
        scaleType = 'linear';
      }
    }
  }
  const scale = {};
  // chart category options
  const showPercentage =
    showPercentageChart &&
    ['barStack', 'columnStack', 'areaStack', 'bar', 'column'].includes(
      chartType
    );
  scale.value = {
    min: yAxisMin,
    max: yAxisMax,
    tickCount: seriesTickCount || 5,
    alias: series?.[0]?.value,
    formatter: (num) => {
      if (num % 1 != 0) {
        return showPercentage ? `${num.toFixed(2)}%` : num;
      }
      return showPercentage ? `${num}%` : num;
    },
  };

  // chart series options
  scale.series = {
    min: xAxisMin,
    max: xAxisMax,
    tickCount: categoryTickCount || null,
    alias: xAxis.value,
    type: scaleType,
  };
  scale.customToolTipField = {
    alias: customToolTipProperties
      ? customToolTipProperties.customToolTipField
      : undefined,
  };
  scale.bubblesize = {
    alias: bubbleProperties ? bubbleProperties.bubbleSize : undefined,
  };

  return scale;
};

export const sortBasedOnCustomList = (list, data, sortProp) => {
  if (list.length > 0 && data.length > 0 && sortProp) {
    const customComparison = (a, b) => {
      if (list.indexOf(a[sortProp]) < list.indexOf(b[sortProp])) {
        return -1;
      }
      if (list.indexOf(a[sortProp]) > list.indexOf(b[sortProp])) {
        return 1;
      }
      return 0;
    };
    return data.sort(customComparison);
  }
  return data;
};

export const sortBasedOnComponentSeries = (
  series,
  data,
  sortField,
  component
) => {
  // eslint-disable-next-line no-debugger
  if (series.length > 0) {
    const listToBaseOnSort = series.map((serie) => serie.value);
    if (!component.disableFillNullValues) {
      return setDefaultValuesForAllGroupByCategories(listToBaseOnSort, data);
    } else {
      return sortBasedOnCustomList(listToBaseOnSort, [...data], 'indicator');
    }
  }
  return data;
};

export const setDefaultValuesForAllGroupByCategories = (
  listToBaseOnSort,
  sortBasedOnCounts
) => {
  const dataArray = [];
  const groupBysAvailableInData = [
    ...new Set(sortBasedOnCounts.map((d) => d.indicator)),
  ];
  const getCategorySortListing = [
    ...new Set(sortBasedOnCounts.map((d) => d.series)),
  ];
  getCategorySortListing.forEach((item) => {
    const categoryData = sortBasedOnCounts.filter(
      (record) => record.series === item
    );
    const categoryIndicators = categoryData.map(
      (category) => category.indicator
    );
    let difference = listToBaseOnSort.filter(
      (x) => !categoryIndicators.includes(x)
    );
    difference = difference.filter((diff) =>
      groupBysAvailableInData.includes(diff)
    );
    if (difference.length > 0) {
      difference.forEach((record, key) => {
        if (categoryData[key]?.indicator !== record) {
          categoryData.splice(key, 0, {
            series: item,
            indicator: record,
            value: 0,
            sortValue: 0,
          });
        } else if (!categoryData[key]?.indicator) {
          categoryData.push({
            series: item,
            indicator: record,
            value: 0,
            sortValue: 0,
          });
        }
      });
    }
    const sortPerCategory = sortBasedOnCustomList(
      listToBaseOnSort,
      categoryData,
      'indicator'
    );

    dataArray.push(...sortPerCategory);
  });
  return dataArray;
};

export const sortGroupedSeriesData = (
  groupedData,
  sortOrder,
  sortField,
  component
) => {
  const result = [];

  // Get totals based on groups
  groupedData.reduce((res, value) => {
    if (!res[value.series]) {
      res[value.series] = { series: value.series, value: 0, sortValue: 0 };
      result.push(res[value.series]);
    }
    res[value.series].value += value.value;
    sortField && (res[value.series].sortValue += value.sortValue);
    return res;
  }, {});
  let sortedGroupedData;
  // sort based on sortField else sort based on value
  if (sortField) {
    sortedGroupedData = result
      .sort((a, b) =>
        sortOrder === 'asc'
          ? b.sortValue - a.sortValue
          : a.sortValue - b.sortValue
      )
      .map((data) => data.series);
  } else {
    sortedGroupedData = result
      .sort((a, b) =>
        sortOrder === 'asc' ? b.value - a.value : a.value - b.value
      )
      .map((data) => data.series);
  }
  const sortBasedOnCounts = sortBasedOnCustomList(
    sortedGroupedData,
    groupedData,
    'series'
  );
  const listToBaseOnSort = component.series[0].groupByColors.map(
    (item) => item.value
  );
  return setDefaultValuesForAllGroupByCategories(
    listToBaseOnSort,
    sortBasedOnCounts
  );
};

export const getValue = (field, target) => {
  if (field && field[target]) {
    return field[target];
  }
  return null;
};

export const getTickCount = (data) => {
  let tickCount;
  if (data && data.length) {
    tickCount = data.length;
  }
  return tickCount;
};

/**
 * @param
 * @returns
 */
export const getSeriesLabel = (id, props) => {
  props.post.columns.filter((column) => column.dataIndex === id);
};

/**
 * @param
 * @returns
 */
const getSeriesValue = (series) => {
  if (Array.isArray(series.value)) {
    let activeIndex = 0;
    if (series.activeSeriesIndex) {
      activeIndex = series.activeSeriesIndex;
    }
    return series.value[activeIndex];
  } else {
    return series.value;
  }
};

// largest remainder method

export const largestRemainderMethod = (items, total) => {
  let n = items.length;
  let quotas = new Array(n);
  let remainders = new Array(n);

  let sum = items.reduce((a, b) => a + b.value, 0);
  let assignedTotal = 0;

  for (let i = 0; i < n; i++) {
    quotas[i] = Math.floor((total * items[i].value) / sum);
    remainders[i] = (total * items[i].value) / sum - quotas[i];
    assignedTotal += quotas[i];
  }

  while (assignedTotal < total) {
    let maxRemainderIndex = remainders.indexOf(Math.max(...remainders));
    quotas[maxRemainderIndex]++;
    remainders[maxRemainderIndex] = -1;
    assignedTotal++;
  }

  return quotas;
};

export const getPercData = (data, series, cube) => {
  const seriesValues = series?.map((serie) => serie?.value);
  if (seriesValues.length > 0 && data.length > 0) {
    const perc_data = data.map((obj, key) => {
      let orderedValues = seriesValues
        .map((orderedKey) =>
          obj[`${cube}.${orderedKey}`] !== undefined
            ? {
                key: `${cube}.${orderedKey}`,
                value: parseFloat(obj[`${cube}.${orderedKey}`]),
              }
            : null
        )
        .filter(Boolean);

      const results = largestRemainderMethod(orderedValues, 100);
      let out = {
        ...obj,
        ...orderedValues.reduce((acc, item, index) => {
          acc[item.key] = results[index];
          return acc;
        }, {}),
      };
      return out;
    });
    return perc_data;
  }

  return data;
};

export const getGroupedPercData = (data, component) => {
  const { cube, series, xAxis } = component;
  const seriesValues = series?.map((serie) => serie?.value);

  const groupedData = [];

  const categories = data.map((record) => record[`${cube}.${xAxis.value}`]);
  // set totals for grouped series values
  [...new Set(categories)].forEach((category, key) => {
    const totals = {};
    const categoryData = data.filter(
      (record) => record[`${cube}.${xAxis.value}`] === category
    );

    categoryData.forEach((record) => {
      seriesValues.map((item) => {
        const dataRecord = Number(record[`${cube}.${item}`]);
        totals[item] = totals[item] ? totals[item] + dataRecord : dataRecord;
      });
    });
    // set percentages for grouped series
    const percentHolder = [];
    categoryData.forEach((record) => {
      Object.keys(totals).forEach((series) => {
        if (record[`${cube}.${series}`]) {
          percentHolder.push({
            ...record,
            [`${cube}.${series}`]:
              (Number(record[`${cube}.${series}`]) / totals[series]) * 100,
          });
        }
      });
    });

    groupedData.push(...percentHolder);
  });

  return groupedData;
};
/**
 * @param
 * @returns
 */
export const getSeries = (component, data) => {
  const {
    sortField,
    sortOrder,
    limit,
    xAxis,
    bubbleProperties,
    customToolTipProperties,
    cube,
    disableFillNullValues,
  } = component;

  let seriesData;
  let axis;

  if (component.xAxis && component.xAxis.value) {
    axis = component.granularity
      ? `${xAxis.value}.${component.granularity}`
      : xAxis.value;
  } else {
    axis = component.granularity ? `${xAxis}.${component.granularity}` : xAxis;
  }

  let dataArray = [];
  const createChartObj = (item) => {
    component.series.map((series) => {
      if (item[`${cube}.${series.value}`]) {
        const obj = {
          series: formatSeries(item[`${cube}.${axis}`]),
          indicator: getSeriesValue(series),
          value: !disableFillNullValues
            ? Number(item[`${cube}.${series.value}`])
            : item[`${cube}.${series.value}`] === null
            ? item[`${cube}.${series.value}`]
            : Number(item[`${cube}.${series.value}`]),
          sortValue:
            item[`${cube}.${sortField}`] && !isNaN(item[`${cube}.${sortField}`])
              ? Number(item[`${cube}.${sortField}`])
              : 0,
        };
        if (bubbleProperties) {
          const { bubbleSize } = bubbleProperties;
          if (bubbleSize) {
            obj.bubblesize = parseInt(item[`${cube}.${bubbleSize}`]);
          }
        }
        if (customToolTipProperties) {
          const { customToolTipField } = customToolTipProperties;
          obj.customToolTipField = item[`${cube}.${customToolTipField}`];
        }
        if (series.groupBy && series.colorMode !== 'generatedStepsBrakes') {
          obj.indicator = item[`${cube}.${series.groupBy}`];
        }
        if (obj.series !== undefined) {
          dataArray.push(obj);
        }
      }
    });
  };
  if (data && data.length > 0) {
    if (
      component.showPercentageChart &&
      ['barStack', 'columnStack', 'areaStack', 'bar', 'column'].includes(
        component.chartType
      )
    ) {
      const lookup = data.reduce((firstVal, lastVal) => {
        firstVal[lastVal[`${cube}.${axis}`]] =
          ++firstVal[lastVal[`${cube}.${axis}`]] || 0;
        return firstVal;
      }, {});

      // records on same axis indicate a group by (get the data aggregated by )
      const recordsOnSameAxis = data.filter(
        (e) => lookup[e[`${cube}.${axis}`]]
      );

      if (!recordsOnSameAxis.length > 0) {
        seriesData = getPercData(data, component.series, cube);
      } else {
        // build group by percentages
        const uniqueRecords = data.filter((e) => !lookup[e[`${cube}.${axis}`]]);

        const unGroupedRecords = getPercData(
          uniqueRecords,
          component.series,
          cube
        );
        const groupedRecords = getGroupedPercData(recordsOnSameAxis, component);
        seriesData = [...unGroupedRecords, ...groupedRecords];
      }
    }
    (seriesData || data).forEach((item, index) => {
      if (index < 1500) {
        createChartObj(item);
      }
    });
  }
  if (sortField !== component?.xAxis?.value) {
    if (sortOrder === 'desc') {
      dataArray.sort((a, b) => a.value - b.value);
    } else {
      dataArray.sort((a, b) => b.value - a.value);
    }
  }

  if (sortField === component.xAxis?.value) {
    if (sortOrder === 'desc') {
      dataArray.sort((a, b) => {
        if (isNaN(a.series) === false) {
          return b.series - a.series;
        } else {
          if (a.series < b.series) {
            return -1;
          }
          if (a.series > b.series) {
            return 1;
          }
          return 0;
        }
      });
    } else {
      dataArray.sort((a, b) => {
        if (isNaN(a.series) === false) {
          return a.series - b.series;
        } else {
          if (b.series < a.series) {
            return -1;
          }
          if (b.series > a.series) {
            return 1;
          }
          return 0;
        }
      });
    }
  }

  // order based on groupby color listing
  if (
    component.series?.length === 1 &&
    component.series?.[0]?.groupByColors?.length &&
    component.series?.[0]?.colorMode !== 'generatedStepsBrakes'
  ) {
    const list = component.series?.[0]?.groupByColors;
    if (list?.length > 0) {
      dataArray = sortBasedOnComponentSeries(
        list,
        dataArray,
        sortField,
        component
      );
    }
  }

  //  Apply limits based on sort order

  if (limit && sortOrder === 'asc') {
    dataArray = dataArray.filter((item, index) => index < limit);
  }
  if (limit && sortOrder === 'desc') {
    dataArray = dataArray.filter(
      (item, index) => index + 1 > dataArray.length - limit
    );
  }

  // collator handles nulls
  let collator = new Intl.Collator();
  if (!component.sortField) {
    // sort by series
    dataArray.sort((a, b) => collator.compare(a.series, b.series));
    // group data by indicator
    dataArray.sort((a, b) => collator.compare(a.indicator, b.indicator));
  }
  if (
    dataArray?.length > 0 &&
    component.series?.[0]?.groupByColors?.length &&
    component.series?.[0]?.colorMode !== 'generatedStepsBrakes' &&
    sortField &&
    sortOrder &&
    sortField !== component?.xAxis?.value
  ) {
    dataArray = sortGroupedSeriesData(
      dataArray,
      sortOrder,
      sortField,
      component
    );
  }
  // order based on series listing
  if (component.series.length > 1) {
    const list = component.series.map((item) => item.value);
    dataArray = sortBasedOnCustomList(list, dataArray, 'indicator');
  }
  return dataArray;
};

const trimString = (str, maxLength) => {
  if (str?.length > maxLength && maxLength) {
    return `${str.substring(0, maxLength)} ...`;
  }
  return str;
};

/**
 * @param
 * @returns
 */
export const formatSeries = (value) => {
  const isDate = moment(value, true).format('x');
  if (value === null) {
    return '';
  }
  if (isNaN(value) === false) {
    return value;
  }

  if (isDate !== 'Invalid date') {
    return String(moment(value).format('YYYY/MM/DD'));
  }
  return String(value);
};

export const colorGetter = (component, value) => {
  let color = '#cccccc';
  component.series.forEach((item) => {
    // single series only
    if (component.series.length === 1) {
      color = item.color;
    }
    // series with group by
    if (
      item.groupByColors &&
      item.groupByColors.length > 0 &&
      item.colorMode !== 'generatedStepsBrakes'
    ) {
      item.groupByColors.forEach((group) => {
        if (group.value === value) {
          color = group.color;
        }
      });
    }
    // multiple series
    if (component.series.length > 1) {
      if (item.value === value) {
        color = item.color;
      }
    }
  });
  return color;
};

export const getChartColorBasedOnValue = (ungroupedData, value) => {
  let color = '#cccccc';
  ungroupedData.every((record, index) => {
    if (Number(value) <= ungroupedData[index + 1]?.value) {
      color = record?.color;
      return false;
    }
    return true;
  });
  return color;
};

/**
 * @param
 * @returns
 */
export const getColors = (component, data) => {
  const { series } = component;
  if (
    series?.[0]?.ungroupedData?.length > 0 &&
    series?.[0]?.colorMode === 'generatedStepsBrakes' &&
    data.length > 0
  ) {
    const ungroupedData = series?.[0]?.ungroupedData;

    return [
      'value',
      (value) => {
        return getChartColorBasedOnValue(ungroupedData, value) || '#cccccc';
      },
    ];
  }
  return [
    'indicator',
    (value) => {
      return colorGetter(component, value);
    },
  ];
};

export const getStyle = (x, component) => {
  let fillColor = component?.series?.[0]?.color;
  if (
    x?.indicator &&
    component?.series?.[0]?.groupByColors?.length > 0 &&
    component.series?.[0]?.colorMode !== 'generatedStepsBrakes'
  ) {
    fillColor = component?.series?.[0]?.groupByColors?.find(
      (groupColor) => groupColor.value === x?.indicator
    )?.color;
  }

  return {
    fill: fillColor,
    stroke: component.strokeColor,
    lineWidth: component?.lineWidth || 1,
    strokeOpacity: component?.strokeOpacity || 1,
    fillOpacity: component?.opacity || 0.3,
    strokeColor: component.strokeColor,
  };
};
//   ];
// };

export const getLabelPosition = (labelRotation) => {
  if (labelRotation) {
    if ([90, 360].includes(labelRotation)) {
      return 'center';
    } else if (labelRotation < 180) {
      return 'left';
    } else if (labelRotation > 180) {
      return 'right';
    }
  }
};

export const getLabelOffset = (labelOffset, rotate) => {
  if ([90].includes(rotate)) {
    return 25;
  }
  return labelOffset || undefined;
};

/**
 * @param
 * @returns
 */
export const getLabelStyle = (component, axis, source, compositeValue) => {
  let labelOffset, labelRotate, labelTextMaxLength, hideLabels;
  const {
    xLabelOffset,
    yLabelOffset,
    xLabelRotate,
    yLabelRotate,
    labelFill,
    ytextMaxLength,
    xtextMaxLength,
    hideCategoryLabel,
    hideSeriesLabel,
    dateFormat,
    series,
  } = component;
  if (axis === 'x') {
    labelOffset = xLabelOffset;
    labelRotate = xLabelRotate;
    labelTextMaxLength = xtextMaxLength;
    hideLabels = hideCategoryLabel;
  } else {
    labelOffset = yLabelOffset;
    labelRotate = yLabelRotate;
    labelTextMaxLength = ytextMaxLength;
    hideLabels = hideSeriesLabel;
  }
  if (component.chartType === 'pie') {
    // get's labels for the interval only

    if (!hideLabels || hideLabels === false) {
      return [
        'value',
        {
          offset: getLabelOffset(labelOffset, labelRotate),
          rotate: labelRotate * (Math.PI / 180),
          style: {
            fill: labelFill || '#222222',
            textAlign: getLabelPosition(labelRotate),
            fontSize: 10,
          },
          autoHide: true,
          content: (data) => {
            const pieChartString = `${data.indicator}: ${Math.round(
              (data.value / compositeValue) * 100
            )}`;
            let formattedLabel = `${trimString(
              pieChartString,
              labelTextMaxLength
            )}%`;
            if (dateFormat) {
              formattedLabel = genericDateFormatter(dateFormat, formattedLabel);
            }
            return formattedLabel;
          },
        },
      ];
    } else {
      return '';
    }
  }

  if (!hideLabels || hideLabels === 'false') {
    return {
      rotate: labelRotate * (Math.PI / 180),
      offset: getLabelOffset(labelOffset, labelRotate),
      style: {
        fill: labelFill || '#555',
        textAlign: getLabelPosition(labelRotate),
        textBaseline: [90].includes(labelRotate) ? 'middle' : undefined,
      },
      formatter: (text) => {
        let formattedLabel = trimString(text, labelTextMaxLength);
        if (dateFormat && axis === 'x') {
          formattedLabel = genericDateFormatter(dateFormat, formattedLabel);
        }
        if (axis === 'y') {
          const properties =
            series &&
            series.filter(
              (serie) => source?.labels?.[serie.value]?.format !== undefined
            );
          formattedLabel =
            properties.length > 0 && !formattedLabel.includes('%')
              ? formatValue(properties[0], formattedLabel, source)
              : formattedLabel;
        }
        return formattedLabel;
      },
    };
  } else {
    return null;
  }
};

/**
 * @param
 * @returns
 */
export const getLegendStatus = (props) => {
  const showLegend = props.component.legend;
  if (!showLegend || showLegend === false) {
    return false;
  } else {
    return true;
  }
};

/**
 * @param
 * @returns
 */
export const getPadding = (props, index) => {
  const padding = props.component.padding;
  if (padding && padding[index]) {
    return padding[index];
  } else {
    return 0;
  }
};

export const toolTipJsxBuilder = (header, color, name, tooltipValue) => {
  return (
    <div>
      <div style={{ marginTop: '15px', marginBottom: '0px' }}>
        <h2>{header}</h2>
      </div>
      <table
        style={{
          marginTop: '5px',
          marginBottom: '10px',
        }}
      >
        <tr></tr>
        <td
          style={{
            padding: '5px',
          }}
        >
          <div
            style={{
              backgroundColor: color,
              width: '8px',
              height: '8px',
              borderRadius: '50%',
            }}
          ></div>
        </td>
        <td
          style={{
            padding: '5px',
            lineHeight: '1.4',
          }}
        >
          {name}
        </td>
        <td
          style={{
            padding: '5px',
            textAlign: 'right',
          }}
        >
          <b>{tooltipValue}</b>
        </td>
      </table>
    </div>
  );
};

export const toolTipBuilder = (title, items, component, componentSource) => {
  const { series, chartType, dateFormat } = component;
  let property;
  // get property for series value
  const color = items[0].color;
  if (chartType === 'treeMap') {
    const toolTipTitle = items[0].data.name;
    const header = dateFormat
      ? genericDateFormatter(dateFormat, toolTipTitle)
      : toolTipTitle;

    let name = series.map((property) => property.value);
    if (
      series?.[0].groupByColors?.length > 0 &&
      series?.[0].groupBy &&
      series?.[0]?.colorMode !== 'generatedStepsBrakes'
    ) {
      name =
        series?.[0].groupByColors.find(
          (property) => property.value === items[0].data.indicator
        )?.value || name;
    }

    if (componentSource?.labels?.[name]?.title) {
      name = componentSource?.labels?.[name]?.title;
    }
    const value = items[0].data.value;

    const tooltipValue = formatValue(
      { name: items[0]?.data?.indicator },
      typeof value === 'string' && value.includes('%')
        ? value.split('%')[0]
        : value,
      componentSource
    );
    const percentageValue = component.showPercentageChart
      ? Math.round(tooltipValue)
      : tooltipValue;
    return toolTipJsxBuilder(header, color, name, percentageValue);
  }
  const name =
    componentSource?.labels?.[items[0].name]?.title ||
    items[0].data.indicator ||
    items[0].name;
  const value = items[0].value;

  property = series?.find(
    (property) => property.value === items[0].data?.indicator
  );

  let tooltipValue = property
    ? formatValue(
        property,
        typeof value === 'string' && value.includes('%')
          ? value.split('%')[0]
          : value,
        componentSource
      )
    : value;
  // get property for groupby datasets

  if (series?.length > 0) {
    series.forEach((serie) => {
      if (
        serie.groupByColors?.length > 0 &&
        serie.groupBy &&
        serie?.[0]?.colorMode !== 'generatedStepsBrakes'
      ) {
        property = serie.groupByColors.find(
          (property) => property.value === items[0].data.indicator
        );
        if (property) {
          property = {
            ...property,
            seriesValue: serie.value,
          };
          tooltipValue = formatValue(
            property,
            typeof value === 'string' && value.includes('%')
              ? value.split('%')[0]
              : value,
            componentSource
          );
        }
      }
    });
  }
  const percentageValue = component.showPercentageChart
    ? Math.round(tooltipValue)
    : tooltipValue;
  const header = dateFormat
    ? genericDateFormatter(dateFormat, title)
    : items[0]?.title === ''
    ? items[0]?.title
    : title;

  return toolTipJsxBuilder(header, color, name, percentageValue);
};

export const formatBubbleToolTip = (e, component, componentSource) => {
  const { data } = e;
  [data.items[0]].forEach((item) => {
    let property;
    // get property for non group by
    property = component.series.find(
      (property) => property.value === item.data?.indicator
    );

    // get property for groupby datasets
    if (component.series?.length > 0) {
      component.series.forEach((serie) => {
        if (
          serie.groupByColors?.length > 0 &&
          serie.groupBy &&
          serie?.[0]?.colorMode !== 'generatedStepsBrakes'
        ) {
          property = serie.groupByColors.find(
            (property) => property.value === item.data.indicator
          );
          if (property) {
            property = {
              ...property,
              seriesValue: serie.value,
            };
          }
        }
      });
    }

    if (property) {
      item.value = formatValue(property, item.data.value, componentSource);
    }
    item.name = item.data?.indicator;
  });
};

export const formatLegendItemName = (text, componentSource) => {
  return (
    (componentSource.labels && componentSource.labels[text]?.title) || text
  );
};

export const numberFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  if (!item && !isNaN(num)) {
    return Math.round(num);
  }

  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : num;
};

export const formatChartData = (data, showPercentageChart) => {
  if (!isNaN(data.value)) {
    if (showPercentageChart) {
      return `${Math.round(data.value)}%`;
    } else {
      return numberFormatter(data.value);
    }
  }
  return undefined;
};
