
export const text = [
  {
    type: 'color',
    value: 'headingColor',
    label: 'Heading color',
    defaultValue: '#111111'
  },
  {
    type: 'font',
    value: 'headingFontFamily',
    label: 'Heading font',
    placeholder: 'Poppins'
  },
  {
    type: 'number',
    value: 'textFontSize',
    label: 'Font size',
    placeholder: 18
  },
  {
    type: 'color',
    value: 'textColor',
    label: 'Text color',
    defaultValue: '#111111'
  },
  {
    type: 'font',
    value: 'bodyFontFamily',
    label: 'Text font',
    placeholder: 'PT Serif'
  },
]