import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { UserPic } from '../../../../Post/UserPic';
import { Row, Col, Input, Tag, Space, Breadcrumb, Popconfirm, Table, Button, message } from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { withRouter, useHistory } from 'react-router-dom';
import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionPostsGet,
} from '../../../actions';
import moment from 'moment';
import { actionPostDelete } from '../../../../Post/actions';
import { getTags } from '../../helpers.js';
import './style.css';
import { ERROR_GENERIC } from '../../../../../configs/constants';
import { IDENTITY_API, POSTS_API } from '../../../../../configs/env';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { genericWriteAccessHandler } from '../../../../Profile/components/ProfileMenu/helpers';
import mixpanel from 'mixpanel-browser';

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionPostsGet,
  actionPostDelete,
};

const SpaceView = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchColumn, setSearchColumn] = useState();
  const [searchText, setSearchText] = useState();
  const [sourceToDeleteUuid, setSourceToDeleteUuid] = useState(null);
  let searchInput;
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    setShowLoader(true);

    const service = new AkukoAPIService(IDENTITY_API, 'space');
    service
      .read(params.id)
      .then((space) => {
        props.actionAccountSpaceGet(space);
        const service = new AkukoAPIService(POSTS_API, `post/space/${params.id}`);
        return service.list();
      })
      .then((data) => {
        props.actionPostsGet(data);
        setPosts(data);
      })
      .finally(() => {
        setShowLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, sourceToDeleteUuid]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn('title');
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  if (props.space.id) {
    return (
      <div className="dashboard">
        <div className="spaces">
          <div className="breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/account/${props.space.account_id}`}>{props.space.account_id}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/account/${props.space.account_id}`}>spaces</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/space/${props.space.id}`}>{props.space.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>posts</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1>Posts</h1>
          <div className="space-header">
            <Row gutter={20}>
              <Col xs={24}>
                {!genericWriteAccessHandler(props.user, props.space.id, undefined, true) ? (
                  <></>
                ) : (
                  <Button
                    hidden={!genericWriteAccessHandler(props.user, props.space.id, undefined, true)}
                    type="primary"
                    onClick={() => {
                      const service = new AkukoAPIService(POSTS_API, 'post');
                      service
                        .create({
                          account_id: props.space.account_id,
                          space_id: props.space.id,
                          title: 'My new post',
                          edit: props.user.email,
                          author: props.user.email,
                          components: [
                            {
                              type: 'title',
                              name: 'My new post',
                              description: '',
                            },
                          ],
                          data: {},
                          sources: {},
                        })
                        .then((res) => {
                          mixpanel.track('Create Post', { post: res.uuid });
                          history.push(`/post/${res.uuid}`);
                        })
                        .catch((error) => {
                          message.error(error.message || ERROR_GENERIC);
                        });
                    }}
                  >
                    New Post
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <Row gutter={20}>
            <Col xs={24} md={24} xl={24}>
              <div className="table-wrapper">
                <Table
                  pagination={{
                    defaultPageSize: 20,
                  }}
                  loading={showLoader}
                  columns={[
                    {
                      title: '',
                      width: '10px',
                      render: (record) => {
                        return <UserPic author={record.author} />;
                      },
                    },
                    {
                      title: 'Title',
                      dataIndex: 'title',
                      filterDropdown: ({
                        setSelectedKeys,
                        selectedKeys,
                        confirm,
                        clearFilters,
                      }) => (
                        <div style={{ padding: 8 }}>
                          <Input
                            ref={(node) => {
                              searchInput = node;
                            }}
                            placeholder={`Search title`}
                            value={selectedKeys[0]}
                            onChange={(e) =>
                              setSelectedKeys(e.target.value ? [e.target.value] : [])
                            }
                            onPressEnter={() => handleSearch(selectedKeys, confirm, 'title')}
                            style={{ marginBottom: 8, display: 'block' }}
                          />
                          <Space>
                            <Button
                              type="primary"
                              onClick={() => handleSearch(selectedKeys, confirm, 'title')}
                              icon={<SearchOutlined />}
                              size="small"
                              style={{ width: 90 }}
                            >
                              Search
                            </Button>
                            <Button
                              type="link"
                              onClick={() => handleReset(clearFilters)}
                              size="small"
                              style={{ width: 90 }}
                            >
                              Reset
                            </Button>
                          </Space>
                        </div>
                      ),
                      onFilter: (value, record) =>
                        record['title']
                          ? record['title'].toString().toLowerCase().includes(value.toLowerCase())
                          : '',
                      key: 'title',
                      render: (text, record) => {
                        return <Link to={`/post/${record.uuid}`}>{text}</Link>;
                      },
                    },
                    {
                      title: 'Updated',
                      dataIndex: 'updated',
                      key: 'updated',
                      defaultSortOrder: 'descend',
                      sorter: (a, b) => moment(a.updated) - moment(b.updated),
                      render: (text, record) => {
                        return (
                          <span className="updated">
                            {moment(text).format('MMM DD YYYY - HH:mm')}
                          </span>
                        );
                      },
                    },
                    {
                      title: 'Tags',
                      dataIndex: 'tags',
                      filters: getTags(props.posts),
                      onFilter: (value, record) => {
                        if (record.tags) {
                          return record.tags.includes(value);
                        } else {
                          return null;
                        }
                      },
                      key: 'tags',
                      render: (text, record) => {
                        if (record.tags && record.tags.length) {
                          return (
                            <>
                              {record.tags &&
                                record.tags.map((tag) => (
                                  <Tag key={tag} color={'blue'}>
                                    {tag}
                                  </Tag>
                                ))}
                            </>
                          );
                        } else {
                          return <Tag>None</Tag>;
                        }
                      },
                    },
                    {
                      title: '',
                      dataIndex: 'delete',
                      key: 'delete',
                      render: (text, record) => {
                        return (
                          genericWriteAccessHandler(props.user, record.space_id, record.author) && (
                            <div style={{ textAlign: 'right' }}>
                              <Popconfirm
                                title="Are you sure you want to delete this post?"
                                onConfirm={() => {
                                  setSourceToDeleteUuid(record.uuid);
                                  const service = new AkukoAPIService(
                                    POSTS_API,
                                    `post/${record.uuid}`
                                  );
                                  service.delete().then(() => {
                                    props.actionPostDelete({
                                      uuid: record.uuid,
                                    });
                                    message.success('Post deleted');
                                  });
                                }}
                              >
                                <DeleteOutlined />
                              </Popconfirm>
                            </div>
                          )
                        );
                      },
                    },
                  ]}
                  dataSource={posts}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const SpaceContainer = connect(mapStateToProps, mapDispatchToProps)(SpaceView);

export default withRouter(SpaceContainer);
