import React, { useMemo, useEffect, useState } from 'react';
import './style.css';
import { Button, Checkbox, Select, Radio, Dropdown, Menu } from 'antd';
import { GenericComponent } from '../Component';
import { getComponentById } from '../../../reducers/selectors/post';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import { DownOutlined, BarsOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  actionComponentEventAdd,
  actionPostComponentSettingEdit,
} from '../actions';
import { getContrast } from '../../App/ColorSettings/helpers';
import {
  componentIsVisible,
  layerIsVisble,
  layerPropertyIsActive,
} from './helpers';
import { useFetchComponentData } from '../hooks';
import { getContextRow } from '../helpers';
import { OptionsProps } from '../../../configs/types';
const { Option } = Select;

/** selector factories */
const makeGetComponentById = () => getComponentById;

/** interface for component props */
export interface ButtonProps {
  componentId: string; // id of the icon component
  componentIndex: number; // index of the icon component
  dataRow?: Dictionary;
}

const ButtonComponent: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { componentId, componentIndex, dataRow } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const [current, setCurrent] = useState('0');

  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);

  // get styles

  const button = useSelector((state) => {
    /* @ts-ignore */
    const component = selectComponentById(state, { componentId });

    if (!component) {
      return undefined;
    }
    return component;
  });

  const [isLoading, data] = useFetchComponentData(componentId);

  const { filteredData } = getContextRow(dataRow, data, component, true);

  const dataRowKeys = filteredData?.[0] ? Object.keys(filteredData[0]) : null;
  const dimensionMatchDataRow: Dictionary = {};
  dataRowKeys?.map((key) => {
    if (key.split('.')[1]) {
      dimensionMatchDataRow[key.split('.')[1]] = filteredData?.[0][key];
    }
    dimensionMatchDataRow[key] = filteredData?.[0][key];
  });

  // set button value in cases where context is inherited

  const buttonValue = filteredData?.[0]
    ? dimensionMatchDataRow[component.context]
    : '';

  const genericComponentProps = {
    dataRow,
    componentIndex,
    componentId,
    drawerTitle: 'Button',
  };

  useEffect(() => {
    getKey();
  }, []);

  useEffect(() => {
    getKey();
  }, [post.uuid]);

  const isRoute = (events: Dictionary[]) => {
    let value;
    events.forEach((item: Dictionary) => {
      if (item.effect === 'navigateToUrl' && item.url.charAt(0) === '/') {
        value = item.url;
      }
    });
    return value;
  };

  const getKey = () => {
    button?.items?.forEach((item: Dictionary) => {
      if (item?.events?.length > 0) {
        item?.events?.forEach((event: Dictionary) => {
          if (window.location.pathname.includes(event.url)) {
            setCurrent(item.id);
          }
        });
      }
    });
  };

  const handleEvents = (e: Dictionary, btn: Dictionary) => {
    if (e?.preventDefault && e?.persist) {
      e.preventDefault();
      e.persist();
    }

    const value = e?.currentTarget?.value;
    if (value != null) {
      for (const [itemKey, item] of button?.items?.entries() ?? []) {
        if (item.events?.length) {
          for (const [eventKey, { effect, filter }] of item.events.entries()) {
            if (effect === 'updateFilterValue' && filter) {
              dispatch(
                actionPostComponentSettingEdit({
                  parents: ['items', 'events'],
                  property: 'value',
                  componentIndex,
                  itemIndex: eventKey,
                  value,
                  childIndex: itemKey,
                })
              );
            }
          }
        }
      }
    }
    dispatch(
      actionComponentEventAdd({
        type: 'onButtonClick',
        event: {
          target: {
            checked: e?.target?.checked,
            key: e?.key, // horizontal menu key
            target: e?.target?.outerHTML,
          },
        },
        data: filteredData[0],
        componentId: btn?.id,
      })
    );
  };

  const menu = (
    <Menu>
      {button?.items?.map((btn: Dictionary, index: number) => (
        <Menu.Item
          onClick={(e: Dictionary) => {
            handleEvents(e, btn);
          }}
          key={index}
        >
          {buttonValue && btn.labelContext ? buttonValue : btn.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const horizontalMenu = (
    <Menu
      theme={component.theme || 'light'}
      mode='horizontal'
      style={{ lineHeight: 4 }}
      selectedKeys={[current]}
    >
      {button?.items?.map((btn: Dictionary) => (
        <Menu.Item
          key={btn.id}
          style={{
            color: button?.color,
            fontFamily: button?.fontFamily,
          }}
          onClick={(e: Dictionary) => {
            setCurrent(e.key);
            if (btn.events?.length > 0) {
              const route = isRoute(btn.events);
              if (route !== undefined) {
                return history.push(route);
              } else {
                return handleEvents(e, btn);
              }
            }
          }}
        >
          {buttonValue && btn.labelContext ? buttonValue : btn.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const verticalMenu = (
    <Menu
      theme={component.theme || 'light'}
      mode='vertical'
      style={{ lineHeight: 4 }}
      selectedKeys={[current]}
    >
      {button?.items?.map((btn: Dictionary, index: number) =>
        btn?.divider ? (
          <div key={index} className='menu-divider'>
            {buttonValue && btn.labelContext ? buttonValue : btn.label}
          </div>
        ) : (
          <Menu.Item
            key={btn.id}
            style={{
              color: button?.color,
              fontFamily: button?.fontFamily,
            }}
            onClick={(e: Dictionary) => {
              setCurrent(e.key);
              if (btn.events?.length > 0) {
                const route = isRoute(btn.events);
                if (route !== undefined) {
                  return history.push(route);
                } else {
                  return handleEvents(e, btn);
                }
              }
            }}
          >
            {buttonValue && btn.labelContext ? buttonValue : btn.label}
          </Menu.Item>
        )
      )}
    </Menu>
  );

  const getOptions = () => {
    const array: OptionsProps[] = [];
    button?.items?.forEach((btn: Dictionary) => {
      array.push({
        label: buttonValue && btn.labelContext ? buttonValue : btn.label,
        value: btn.id,
      });
    });
    return array;
  };

  const getCheckboxValue = () => {
    const defaultValue: string[] = [];
    if (button?.items) {
      button?.items.forEach((btn: Dictionary) => {
        if (btn?.events?.length > 1) {
          btn.events.every((evt: Dictionary) => {
            if (evt.effect === 'toggleLayer') {
              const currentLayerIsVisible = layerIsVisble(
                post,
                evt?.componentId,
                evt?.target
              );
              if (currentLayerIsVisible) {
                defaultValue.push(btn.id);
              }
              return;
            }
            if (evt.effect === 'toggleLayout') {
              if (componentIsVisible(post, evt?.target?.[0])) {
                defaultValue.push(btn.id);
              }
              return;
            }
            if (evt?.effect === 'updateLayerProperty') {
              const layerPropertyActive = layerPropertyIsActive(
                post,
                evt?.componentId,
                evt?.target,
                evt
              );
              if (layerPropertyActive) {
                defaultValue.push(btn.id);
              }
              return;
            }
          });
        } else {
          const event = btn?.events?.[0];

          if (event?.effect === 'toggleLayer') {
            const currentLayerIsVisible = layerIsVisble(
              post,
              event?.componentId,
              event?.target
            );
            if (currentLayerIsVisible) {
              defaultValue.push(btn.id);
            }
          }
          if (event?.effect === 'toggleLayout') {
            if (componentIsVisible(post, event?.target?.[0])) {
              defaultValue.push(btn.id);
            }
          }
          if (event?.effect === 'updateLayerProperty') {
            const layerPropertyActive = layerPropertyIsActive(
              post,
              event?.componentId,
              event?.target,
              event
            );
            if (layerPropertyActive) {
              defaultValue.push(btn.id);
            }
          }
        }
      });
    }
    return defaultValue;
  };

  const getRadioValue = () => {
    let defaultValue = 'Select';
    if (button?.items) {
      button?.items.forEach((btn: Dictionary) => {
        if (btn?.events?.length > 1) {
          btn.events.forEach((evt: Dictionary) => {
            if (evt.effect === 'showLayer') {
              const currentLayerIsVisible = layerIsVisble(
                post,
                evt?.componentId,
                evt?.target
              );
              if (currentLayerIsVisible) {
                defaultValue = btn.id;
              }
            }
            if (evt?.effect === 'toggleLayout') {
              if (componentIsVisible(post, evt?.target?.[0])) {
                defaultValue = btn.id;
              }
            }
            if (evt?.effect === 'updateLayerProperty') {
              const layerPropertyActive = layerPropertyIsActive(
                post,
                evt.componentId,
                evt?.target,
                evt
              );
              if (layerPropertyActive) {
                defaultValue = btn.id;
              }
            }
          });
        } else {
          const event = btn?.events?.[0];
          if (event?.effect === 'toggleLayer') {
            const currentLayerIsVisible = layerIsVisble(
              post,
              event?.componentId,
              event?.target
            );
            if (currentLayerIsVisible) {
              defaultValue = btn.id;
            }
          }
          if (event?.effect === 'toggleLayout') {
            if (componentIsVisible(post, event?.target?.[0])) {
              defaultValue = btn.id;
            }
          }
          if (event?.effect === 'showLayer') {
            const currentLayerIsVisible = layerIsVisble(
              post,
              event?.componentId,
              event?.target
            );
            if (currentLayerIsVisible) {
              defaultValue = btn.id;
            }
          }
          if (event?.effect === 'updateLayerProperty') {
            const layerPropertyActive = layerPropertyIsActive(
              post,
              event?.componentId,
              event?.target,
              event
            );
            if (layerPropertyActive) {
              defaultValue = btn.id;
            }
          }
        }
      });
    }
    return defaultValue;
  };

  return (
    <GenericComponent {...genericComponentProps}>
      <div style={{ textAlign: button?.textAlign }}>
        {isLoading && (
          <div className='component-loader'>
            <LoadingOutlined />
          </div>
        )}
        <div>
          {button?.format === 'dropdown' && (
            <Dropdown overlay={menu}>
              <Button
                style={{
                  background: button?.backgroundColor,
                  color: button.color,
                }}
                value={buttonValue}
              >
                {button?.title || 'Menu'} <DownOutlined />
              </Button>
            </Dropdown>
          )}
          {button?.format === 'select' && (
            <Select
              style={{
                background: button?.backgroundColor,
                color: getContrast(button?.backgroundColor || '#111111'),
              }}
              placeholder={'Select...'}
              value={getRadioValue()}
              defaultValue={getRadioValue()}
              onChange={(value) => {
                // handle checked
                handleEvents(
                  {
                    value: true,
                    children: 'select',
                    target: {
                      checked: true,
                    },
                  },
                  { id: value }
                );
                // handle unchecked
                button?.items?.forEach((btn: Dictionary) => {
                  if (value !== btn.id) {
                    setTimeout(() => {
                      handleEvents(
                        {
                          value: false,
                          children: 'select',
                          target: {
                            checked: false,
                          },
                        },
                        { id: btn.id }
                      );
                    }, 100);
                  }
                });
              }}
            >
              {button?.items?.map((btn: Dictionary, index: number) => (
                <Option value={btn.id} key={index}>
                  {buttonValue && btn.labelContext ? buttonValue : btn.label}
                </Option>
              ))}
            </Select>
          )}
          {button?.format === 'radio' && (
            <Radio.Group
              options={getOptions()}
              value={getRadioValue()}
              defaultValue={getRadioValue()}
              onChange={(e: Dictionary) => {
                // handle checked
                handleEvents(
                  {
                    value: true,
                    children: 'radio',
                    target: {
                      checked: true,
                    },
                  },
                  { id: e?.target?.value }
                );
                // handle unchecked
                button?.items?.forEach((btn: Dictionary) => {
                  if (e?.target?.value !== btn.id) {
                    setTimeout(() => {
                      handleEvents(
                        {
                          value: false,
                          children: 'radio',
                          target: {
                            checked: false,
                          },
                        },
                        { id: btn.id }
                      );
                    }, 100);
                  }
                });
              }}
            />
          )}
          {button?.format === 'pills' && (
            <Radio.Group
              options={getOptions()}
              value={getRadioValue()}
              defaultValue={getRadioValue()}
              optionType='button'
              buttonStyle='solid'
              onChange={(e: Dictionary) => {
                // handle checked
                handleEvents(
                  {
                    value: true,
                    children: 'radio',
                    target: {
                      checked: true,
                    },
                  },
                  { id: e?.target?.value }
                );
                // handle unchecked
                button?.items?.forEach((btn: Dictionary) => {
                  if (e?.target?.value !== btn.id) {
                    setTimeout(() => {
                      handleEvents(
                        {
                          value: false,
                          children: 'radio',
                          target: {
                            checked: false,
                          },
                        },
                        { id: btn.id }
                      );
                    }, 100);
                  }
                });
              }}
            />
          )}
          {button?.format === 'checkbox' && (
            <Checkbox.Group
              options={getOptions()}
              value={getCheckboxValue()}
              defaultValue={getCheckboxValue()}
              onChange={(values: Dictionary) => {
                // handle checked
                if (values.length > 0) {
                  values.forEach((value: string) => {
                    setTimeout(() => {
                      handleEvents(
                        {
                          value: true,
                          children: 'checkbox',
                          target: {
                            checked: true,
                          },
                        },
                        { id: value }
                      );
                    }, 100);
                  });
                }
                // handle unchecked
                button?.items?.forEach((btn: Dictionary) => {
                  if (!values.includes(btn.id)) {
                    setTimeout(() => {
                      handleEvents(
                        {
                          value: false,
                          children: 'checkbox',
                          target: {
                            checked: false,
                          },
                        },
                        { id: btn.id }
                      );
                    }, 100);
                  }
                });
              }}
            />
          )}
          {button?.format === 'horizontalMenu' && (
            <div
              style={{
                background: button?.backgroundColor,
              }}
            >
              {horizontalMenu}
            </div>
          )}
          {button?.format === 'verticalMenu' && (
            <div
              style={{
                background: button?.backgroundColor,
              }}
            >
              {verticalMenu}
            </div>
          )}
          {button?.format === 'button' &&
            button?.items?.map((btn: Dictionary, index: number) => (
              <Button
                htmlType='button'
                style={{
                  background: button?.backgroundColor,
                  color: button?.color,
                  borderColor: button?.borderColor,
                }}
                size={button?.size || 'middle'}
                onClick={(e: Dictionary) => {
                  handleEvents(e, btn);
                }}
                key={index}
                value={buttonValue}
              >
                {buttonValue && btn.labelContext ? buttonValue : btn.label}
              </Button>
            ))}
          {!button?.format &&
            button?.items?.map((btn: Dictionary, index: number) => (
              <Button
                style={{
                  background: button?.backgroundColor,
                  color: getContrast(button?.backgroundColor || '#111111'),
                }}
                onClick={(e: Dictionary) => {
                  handleEvents(e, btn);
                }}
                key={index}
                value={buttonValue}
              >
                {buttonValue && btn.labelContext ? buttonValue : btn.label}
              </Button>
            ))}
          {!button?.items && (
            <div className='component-empty'>
              <BarsOutlined />
            </div>
          )}
        </div>
      </div>
    </GenericComponent>
  );
};

export { ButtonComponent };
