import React, { Component, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GenericComponent } from '../Component';
import { PictureOutlined } from '@ant-design/icons';
import { getComponentById, getComponentSource } from '../../../reducers/selectors/post';
import { useFetchComponentData } from '../hooks';
import { actionComponentEventAdd } from '../actions';
import { getContextRow } from '../helpers';
import './style.css';

/** selector factories */
const makeGetComponentById = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;

const Image = (props) => {
  //console.log(props)
  const { componentIndex, dataRow } = props;
  const post = useSelector((state) => state.post);
  const component = post.components[componentIndex];
  const componentId = component?.id;
  const region = component?.region;
  const dispatch = useDispatch();

  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);

  const genericComponentProps = {
    componentIndex,
    componentId,
    drawerTitle: 'Image',
  };

  const source = useSelector((state) => selectComponentSource(state, { componentId }));
  const [isLoading, data] = useFetchComponentData(componentId);

  const { filteredData, layoutIncomponent } = getContextRow(dataRow, data, component, true);

  const handleEvents = (e, btn) => {
    dispatch(
      actionComponentEventAdd({
        type: 'onButtonClick',
        event: {
          target: {
            checked: e?.target?.checked,
            key: e.key, // horizontal menu key
            target: e?.target?.outerHTML,
          },
        },
        data: filteredData[0],
        componentId: btn?.id,
        history: history,
      })
    );
  };

  return (
    <GenericComponent {...genericComponentProps}>
      <div>
        {component.imageSource === 'dimension' && component?.cube ? (
          <img
            onClick={(e) => handleEvents(e, component)}
            style={{ cursor: component?.events?.length > 0 ? 'pointer' : 'default' }}
            src={props.dataRow[`${component?.cube}.${component?.dimensionImageUrl}`]}
            alt={component.caption}
          />
        ) : (
          <>
            {!region && component?.imageUrl && component?.width === 'large' && (
              <div
                className="post--component-image--image"
                style={{
                  height: !component.height && component.region ? 275 : component.height,
                  backgroundImage: `url(${component.imageUrl})`,
                }}
              ></div>
            )}
            {region && component?.imageUrl && (
              <img
                onClick={(e) => handleEvents(e, component)}
                style={{
                  width: `${component.imageWidth}px`,
                  float: component.align !== 'center' ? component.align : 'none',
                  marginLeft: component.align !== 'center' ? 0 : 'auto',
                  marginRight: component.align !== 'center' ? 0 : 'auto',
                  cursor: component?.events?.length > 0 ? 'pointer' : 'default',
                  display: 'block',
                }}
                alt={component.caption}
                src={component.imageUrl}
              />
            )}
            {!region && component?.imageUrl && component?.width !== 'large' && (
              <img
                onClick={(e) => handleEvents(e, component)}
                style={{
                  width: `${component.imageWidth}px`,
                  cursor: component?.events?.length > 0 ? 'pointer' : 'default',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                }}
                alt={component.caption}
                src={component.imageUrl}
              />
            )}
          </>
        )}
        {!component?.imageUrl && !component.dimensionImageUrl && (
          <div className="component-empty">
            <PictureOutlined />
          </div>
        )}
      </div>
    </GenericComponent>
  );
};

export { Image };
