import React, { useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit, actionPostPropertyEdit } from '../../../actions';
const { Option } = Select;

export interface ComponentInputProps {
  componentIndex: number;
  childIndex?: number;
  itemIndex?: number;
  item: Dictionary;
}

const ComponentInput: React.FC<ComponentInputProps> = (props: ComponentInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents } = item;
  const post = useSelector((state: any) => state.post);
  const [value, setValue] = useState();

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentValue =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (componentIndex && !parents) {
      setValue(post.components[componentIndex][property]);
    }
    if (item.entity === 'post') {
      if (post && post.color) {
        setValue(post[property]);
      }
    }
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  return (
    <>
      {label && <label>{label}</label>}
      <Select
        value={value}
        mode={'multiple'}
        onChange={(value) => {
          setValue(value);
          setTimeout(() => {
            if (item.entity === 'post') {
              dispatch(
                actionPostPropertyEdit({
                  property: property,
                  value: value,
                })
              );
            } else {
              dispatch(
                actionPostComponentSettingEdit({
                  parents: parents,
                  property: property,
                  componentIndex: componentIndex,
                  itemIndex: itemIndex,
                  childIndex: childIndex,
                  value: value,
                })
              );
            }
          }, 500);
        }}
      >
        <Option value="select">Select</Option>
        {post.components.map(
          (component: Dictionary, index: number) =>
            component.type === item?.componentType && (
              <Option key={index} value={component.id}>
                {component.name || component.id}
              </Option>
            )
        )}
      </Select>
    </>
  );
};

export { ComponentInput };
