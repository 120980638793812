import React, { useState } from 'react';
import { Input, Button, Form, Select, Row, Col, message } from 'antd';
import { useDispatch, useStore } from 'react-redux';
import { actionSourceNestedItemEdit, actionSourceSave } from '../../actions';
import { AkukoAPIService } from '../../../../services/serviceClass';
import { SOURCES_API, QUERY_API } from '../../../../configs/env';
import { Dictionary } from '@onaio/utils';
import { Dimension, Source } from '../../../../configs/component-types';
import { transformSourceUIDimensionsAndMeasuresToCubeFormat } from '../../helpers/helpers';
import { SUBMIT_BUTTON_TEXTS } from '../constants';
import { DIMENSION_CREATED } from './constants';
import { ERROR_GENERIC } from '../../../../configs/constants';

const { Option } = Select;
const { TextArea } = Input;

interface SourceEditOptions {
  itemIndex: number;
  parentProperty: string;
  property: string;
  value: string;
}

export interface DimensionFormProps {
  item: Dictionary;
  itemIndex: number;
  sourceActionSaveCreator?: (obj: Source) => void;
  sourceNestedItemEditCreator?: (obj: SourceEditOptions) => void;
}

interface InitialValues {
  title: string;
  type: string;
  prefix: string;
  suffix: string;
  format: string;
  value: string;
  sql: string;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
  sourceNestedItemEditCreator: actionSourceNestedItemEdit,
};

const DimensionForm: React.FC<DimensionFormProps> = (props: DimensionFormProps) => {
  const { item, itemIndex, sourceActionSaveCreator } = props;
  const dispatch = useDispatch();
  const store = useStore();
  const currentState = store.getState();
  /* @ts-ignore */
  const sourceObj = Object.assign({}, currentState.source);
  const [isSubmitting, setSubmitting] = useState(false);
  const isDisabled = sourceObj.public ? true : false;

  const formInitialValues: InitialValues = {
    title: item.title,
    type: item.type,
    prefix: item.prefix,
    suffix: item.suffix,
    format: item.format,
    value: item.value,
    sql: item.sql || item?.value,
  };

  return (
    <Form
      layout="vertical"
      initialValues={formInitialValues}
      onFinish={(values) => {
        setSubmitting(true);
        const formValues = values;
        const dimensions =
          sourceObj.dimensions &&
          sourceObj.dimensions.map((dimension: Dimension, index: number) =>
            index === itemIndex ? formValues : dimension
          );
        const { dimensions: cubeDimensions } = transformSourceUIDimensionsAndMeasuresToCubeFormat({
          dimensions: dimensions,
          measures: [],
          cubeDimensions: sourceObj.schema?.dimensions,
          cubeMeasures: [],
        });
        const refreshKey = sourceObj?.refresh_key + 1;
        const schema = { ...sourceObj?.schema, dimensions: cubeDimensions, refreshKey };
        const queryService = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load');
        queryService
          .create({
            uuid: sourceObj.uuid,
            refreshKey: refreshKey,
            cubeName: sourceObj?.cube,
            cubeSchema: schema,
            query: {
              dimensions:
                formValues && Object.keys(formValues)?.length !== 0
                  ? [`${sourceObj.cube}.${formValues.value}`]
                  : [],
              measures: [],
              limit: 1000,
              renewQuery: true,
            },
          })
          .then((res) => {
            const response = res as Dictionary;
            const queryResult = response.data as Dictionary[];
            if (!Array.isArray(queryResult)) {
              throw new Error('Dimension is invalid');
            }
            const updateSourceCubeSchemaService = new AkukoAPIService(
              SOURCES_API,
              `source/cube/${sourceObj.uuid}`
            );
            return updateSourceCubeSchemaService
              .update({ schema, source_type: sourceObj.type })
              .then(() => {
                if (sourceActionSaveCreator) {
                  dispatch(
                    /* @ts-ignore */
                    sourceActionSaveCreator({
                      ...sourceObj,
                      schema: schema,
                      dimensions: dimensions,
                      refresh_key: refreshKey,
                    })
                  );
                }
                message.success(DIMENSION_CREATED);
                setSubmitting(false);
              });
          })
          .catch((error) => {
            setSubmitting(false);
            message.error(error.message || ERROR_GENERIC);
          });
      }}
    >
      <Row gutter={10}>
        <Col xs={24} sm={24}>
          <Form.Item
            label="Name"
            name="value"
            rules={[
              {
                required: true,
                validator: (field, value) => {
                  if (Number(value.substring(0, 1))) {
                    return Promise.reject('Dimension names must begin with a letter.');
                  }
                  if (value.includes(' ')) {
                    return Promise.reject(
                      'No spaces are allowed in Dimension names. Use underscores (_) instead.'
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item label="Label" name="title">
            <Input disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select disabled={isDisabled}>
              <Option value="string">String</Option>
              <Option value="number">Number</Option>
              <Option value="time">Time</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item
            label="SQL"
            name="sql"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea className="cube-input" disabled={isDisabled} rows={4} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item label="Prefix" name="prefix">
            <Input disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item label="Format" name="format">
            <Input disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item label="Suffix" name="suffix">
            <Input disabled={isDisabled} />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit" disabled={isSubmitting || isDisabled}>
        {isSubmitting ? SUBMIT_BUTTON_TEXTS.saving : SUBMIT_BUTTON_TEXTS.default}
      </Button>
    </Form>
  );
};

DimensionForm.defaultProps = defaultProps;

export { DimensionForm };
