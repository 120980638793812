import { setEnv } from './utils';

export const ENV = setEnv('REACT_APP_ENV', 'dev');
export const IDENTITY_API = setEnv('REACT_APP_IDENTITY_API', 'https://identity.api.akuko.io/v1/');
export const SOURCES_API = setEnv('REACT_APP_SOURCES_API', 'https://sources.api.akuko.io/v1/');
export const QUERY_API = setEnv('REACT_APP_QUERY_API', 'https://query.api.akuko.io');
export const SOURCES_WEB_SOCKET_URL = setEnv(
  'REACT_APP_SOURCES_WEB_SOCKET_URL',
  'https://sources.api.akuko.io'
);
export const TEMPORAL_SOURCES_REFRESH_WORKER_WEB_SOCKET_URL = setEnv(
  'REACT_APP_TEMPORAL_SOURCES_REFRESH_WORKER_WEB_SOCKET_URL',
  'https://sources-refresh-worker.api.akuko.io'
);
export const TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL = setEnv(
  'REACT_APP_TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL',
  'https://sources-worker.api.akuko.io'
);
export const TEMPORAL_POST_WORKER_WEB_SOCKET_URL = setEnv(
  'REACT_APP_TEMPORAL_POST_WORKER_WEB_SOCKET_URL',
  'https://posts-worker.api.akuko.io'
);
export const POSTS_API = setEnv('REACT_APP_POSTS_API', 'https://posts.api.akuko.io/v1/');
export const UPLOADS_API = setEnv('REACT_APP_UPLOADS_API', 'https://uploads.api.akuko.io/v1/');
export const ADMIN_API = setEnv('REACT_APP_ADMIN_API', 'https://admin.akuko.io/items/');
export const PM_TILES_CDN_URL = setEnv(
  'REACT_APP_PM_TILES_CDN_URL',
  'https://d3e0zu94abde6b.cloudfront.net/'
);
export const NOTIFICATIONS_API = setEnv(
  'REACT_APP_NOTIFICATIONS_API',
  'https://notifications.api.akuko.io/v1/'
);
export const ASSETS_BUCKET_NAME = setEnv('REACT_APP_ASSETS_BUCKET_NAME', 'assets.akuko.io');
export const POSTS_WEB_SOCKET_URL = setEnv(
  'REACT_APP_POSTS_WEB_SOCKET_URL',
  'https://posts.api.akuko.io'
);
export const REFRESHABLE_DATABASE_SOURCES_TYPES = setEnv(
  'REACT_APP_REFRESHABLE_DATABASE_SOURCES_TYPES',
  'postgres, msql, clickhouse'
);
export const AKUKO_APP_DOMAINS = setEnv('REACT_APP_AKUKO_APP_DOMAINS', 'akuko.io, akuko.co');
export const AKUKO_APP_SITE_UNDER_MAINTENANCE = setEnv(
  'REACT_APP_AKUKO_APP_SITE_UNDER_MAINTENANCE',
  'false'
);
/* @ts-ignore */
export const AKUKO_APP_RETRY_COUNT = Number(setEnv('REACT_APP_RETRY_COUNT', 15)) as number;
/* @ts-ignore */
export const AKUKO_APP_RETRY_INTERVAL = Number(setEnv('REACT_APP_RETRY_INTERVAL', 2000)) as number;
