import React from 'react';
import { Menu, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

export const SpaceMenu = (props) => {
  if (props.space.config && props.space.config.menu && props.space.config.menu.length) {
    return (
      <div className="space-menu" style={{ fontFamily: props.space.config?.font?.bodyFontFamily }}>
        <div className="content">
          <Menu mode="horizontal">
            {props.space.config.menu.map((item, index) => (
              <Menu.Item key={index}>
                {item.type === 'url' && (
                  <a target="blank" href={item.url}>
                    {item.name}
                  </a>
                )}
                {item.type === 'tag' && (
                  <Link to={`/space/${props.space.id}/tag/${item.tag}`}>{item.name}</Link>
                )}
                {item.type === 'post' && <Link to={`/post/${item.post}`}>{item.name}</Link>}
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SpaceMenu;
