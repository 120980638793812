import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
//import axios from "axios";
import { Link } from 'react-router-dom';
import { Menu, Dropdown, message, notification, Button, Row } from 'antd';
import { UserOutlined, MenuOutlined, AppstoreFilled, EllipsisOutlined } from '@ant-design/icons';
import { getActiveAccount, isPost, isSource } from '../helpers';
import {
  actionUserLogout,
  actionUserActiveAccountEdit,
  actionConfigDarkMode,
  actionPostEdit,
} from '../actions';
import { actionConfigComponentIndexActive, actionConfigDrawerOpenEdit } from '../../Post/actions';
import { userIsAdmin, userCanEdit, getAccountUrl } from '../helpers';
import { getAccountRole } from '../helpers.js';
import './Nav.css';
import {
  shouldShowSpaceHeader,
  shouldShowDefaultHeader,
  getColor,
  getHeaderColor,
  getName,
  getDashboardLink,
  spaceContent,
} from './helpers';
import {
  BUTTON_SIGN_IN,
  BUTTON_SIGN_OUT,
  BUTTON_DELETE_POST,
  BUTTON_NEW_POST,
} from '../../../configs/lang';
import SpaceMenu from './components/SpaceMenu';
import { AkukoAPIService } from '../../../services/serviceClass';
import { IDENTITY_API, POSTS_API, SOURCES_API } from '../../../configs/env';
const uuid = require('uuid/v4');

const mapStateToProps = (state, ownProps) => {
  if (!state) {
    return null;
  } else {
    return state;
  }
};

const mapDispatchToProps = {
  actionUserLogout,
  actionUserActiveAccountEdit,
  actionConfigDrawerOpenEdit,
  actionConfigComponentIndexActive,
  actionConfigDarkMode,
  actionPostEdit,
};

class Nav extends Component {
  state = {
    loaded: false,
  };

  render() {
    if (this.props.post.space_id || this.props.location.pathname.includes('/space/')) {
      return (
        <>
          <div
            className={this.props.space?.config?.layout_on_header ? '' : 'nav global'}
            id="nav-global"
            style={{
              background: !this.props.space?.config?.layout_on_header && getHeaderColor(this.props),
            }}
          >
            <div className="content">
              {shouldShowSpaceHeader(this.props) &&
                spaceContent(this.props, this.props.space?.config?.layout_on_header)}
            </div>
          </div>
          {this.props.post.space_id && <SpaceMenu {...this.props} />}
        </>
      );
    }
    return null;
  }
}

const NavContainer = connect(mapStateToProps, mapDispatchToProps)(Nav);

export default withRouter(NavContainer);
