export const actionComponentImageUrlEdit = (data) => {
  return {
    type: "POST_COMPONENT_IMAGE_URL_EDIT",
    data,
  };
};

export const actionComponentImageHeightEdit = (data) => {
  return {
    type: "POST_COMPONENT_IMAGE_HEIGHT_EDIT",
    data,
  };
};
