/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Row, Col, Button, Alert, message } from 'antd';
import { useDispatch, useSelector, useStore } from 'react-redux';
import * as Yup from 'yup';
import { Dictionary } from '@onaio/utils';

import { actionSourceSave } from '../../actions';
import { AkukoAPIService } from '../../../../services/serviceClass';
import { SOURCES_API, QUERY_API } from '../../../../configs/env';
import { SUBMIT_BUTTON_TEXTS, STATUS_MESSAGES } from '../constants';
import { transformCubeDimensionsAndMeasuresToSourceUIFormat } from '../../helpers/helpers';
import { ERROR_GENERIC } from '../../../../configs/constants';

const alertMessageStyle = {
  marginTop: '1em',
};

export interface CubeSchemaFormProps {
  sourceSaveActionCreator?: (obj: Dictionary) => void;
}

interface InitialValues {
  schema: string;
}

/** default component props */
const defaultProps = {
  sourceSaveActionCreator: actionSourceSave,
};

const CubeSchemaForm: React.FC<CubeSchemaFormProps> = (props: CubeSchemaFormProps) => {
  const { sourceSaveActionCreator } = props;
  const dispatch = useDispatch();
  const store = useStore();
  const source = useSelector((store: Dictionary) => store.source);
  const [formError, setFormError] = useState('');
  const validationSchema = Yup.object().shape({
    schema: Yup.string().required('Source cube schema can not be empty'),
  });
  const schema: Dictionary = source?.schema ? { ...source?.schema } : {};

  if (Object.keys(schema).length !== 0) {
    if (schema.sql) {
      delete schema.sql;
    }
    if (schema.sql_table) {
      delete schema.sql_table;
    }
    if (schema.refreshKey) {
      delete schema.refreshKey;
    }
  }

  const formInitialValues: InitialValues = {
    schema:
      Object.keys(schema).length !== 0
        ? JSON.stringify(schema, undefined, 2)
        : JSON.stringify(schema),
  };

  return (
    <Formik
      key={JSON.stringify(formInitialValues.schema)}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        let schema: Dictionary;
        setStatus(STATUS_MESSAGES.default);
        setFormError(STATUS_MESSAGES.default);
        setSubmitting(true);
        const currentState = store.getState();
        try {
          schema = {
            ...JSON.parse(values.schema),
            /* @ts-ignore */
            sql: currentState.source?.schema?.sql,
            /* @ts-ignore */
            refreshKey: currentState.source?.schema?.refreshKey,
          };
          // Replace measures with an empty array when it's just an empty object inside
          if (
            schema.measures &&
            schema.measures.length === 1 &&
            Object.keys(schema.measures[0]).length === 0
          ) {
            schema = { ...schema, measures: [] };
          }
          const queryService = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load');
          const { dimensions, measures } = transformCubeDimensionsAndMeasuresToSourceUIFormat(
            schema.dimensions,
            schema.measures
          );
          queryService
            .create({
              /* @ts-ignore */
              uuid: currentState?.source.uuid,
              /* @ts-ignore */
              refreshKey: currentState?.source?.refresh_key,
              /* @ts-ignore */
              cubeName: currentState?.source?.cube,
              cubeSchema: schema,
              query: {
                dimensions:
                  schema.dimensions?.length !== 0
                  /* @ts-ignore */
                    ? [`${currentState?.source.cube}.${dimensions[0].value}`]
                    : [],
                measures:
                  schema.measures?.length !== 0
                  /* @ts-ignore */
                    ? [`${currentState?.source.cube}.${measures[0].name}`]
                    : [],
                limit: 1000,
                renewQuery: true,
              },
            })
            .then(() => {
              const updateSourceCubeSchemaService = new AkukoAPIService(
                SOURCES_API,
                /* @ts-ignore */
                `source/cube/${currentState?.source.uuid}`
              );
              updateSourceCubeSchemaService
              /* @ts-ignore */
                .update({ schema: schema, source_type: currentState?.source.type })
                .then(() => {
                  setStatus(STATUS_MESSAGES.done);
                  if (sourceSaveActionCreator) {
                    dispatch(
                      /* @ts-ignore */
                      sourceSaveActionCreator({
                        /* @ts-ignore */
                        ...currentState?.source,
                        schema: schema,
                        dimensions: dimensions,
                        measures: measures,
                      })
                    );
                  }
                  setSubmitting(false);
                })
                .catch((error) => {
                  message.error(error.message || ERROR_GENERIC);
                });
            })
            .catch((error) => {
              setStatus(STATUS_MESSAGES.error);
              setFormError(error.message);
              setSubmitting(false);
              message.error(error.message || ERROR_GENERIC);
            });
        } catch (error) {
          setStatus(STATUS_MESSAGES.error);
          setFormError('Incorrect JSON format');
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleSubmit, isSubmitting, status, handleChange }) => (
        <Form onSubmit={handleSubmit}>
          {formError !== STATUS_MESSAGES.default && status === STATUS_MESSAGES.error && (
            <Alert message={formError} type="error" showIcon />
          )}
          {status === STATUS_MESSAGES.done && (
            <Alert message="Schema updated successfully" type="success" showIcon />
          )}
          <Row gutter={10}>
            <Col xs={24}>
              <div className="input-field cube-input">
                <Field name="schema">
                  {({ field, meta }: Dictionary) => (
                    <div>
                      <textarea
                        {...field}
                        className="ant-input"
                        rows={10}
                        onChange={handleChange}
                        disabled={source.public ? true : false || isSubmitting}
                      />
                      {meta.error && (
                        <Alert
                          style={alertMessageStyle}
                          message={meta.error}
                          type="error"
                          showIcon
                        />
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </Col>
          </Row>
          <br />
          <Button
            type="primary"
            htmlType="submit"
            disabled={
              source.public ? true : false || isSubmitting || Object.keys(errors).length !== 0
            }
          >
            {isSubmitting ? SUBMIT_BUTTON_TEXTS.saving : SUBMIT_BUTTON_TEXTS.default}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

CubeSchemaForm.defaultProps = defaultProps;

export { CubeSchemaForm };
